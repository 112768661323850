import React, { useRef } from 'react';
import ApexChart from 'react-apexcharts';

import numeral from 'numeral-es6';
import { SectionTitle } from 'components/ui/Typography';
import { normalizeChartData } from 'shared/visits';
import useComponentSize from '@rehooks/component-size';
import { useAnimations } from 'hooks/useUser';
import DataLoader from 'components/apps/Details/DataLoader';
import css from './style.module.scss';

const prepareSerie = (name, { elements, days }) => ({
  name,
  data: elements,
  categories: days,
});

const SparkLine = ({ data, color }) => {
  const chartType = 'area';
  const chartHeight = '70px';
  const ref = useRef(null);
  const animate = useAnimations();
  const size = useComponentSize(ref);
  const { width } = size;
  const options = {
    chart: {
      animations: {
        enabled: animate,
      },
      sparkline: {
        enabled: true,
      },
      width: '100%',
    },
    tooltip: {
      show: false,
    },
    title: {
      text: '',
      margin: 10,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    colors: [color],
    yaxis: {
      min: -1,
    },
    fill: {
      colors: color,
      type: 'solid',
      opacity: [0.5, 0.5],
      gradient: {
        shade: 'light',
        type: 'vertical',
        opacityFrom: 1,
        opacityTo: 0.4,
        stops: [0, 90, 100],
      },

    },
  };
  return (
    <div ref={ref} className={css.chartContainer}>
      {width > 0 && (
        <ApexChart
          options={options}
          series={data}
          type={chartType}
          width={width}
          height={chartHeight}
        />
      )}
    </div>
  );
};

export const SourceSparkline = ({
  current,
  params,
  color,
  app,
}) => (
  <div className={css.sourceSparkline}>
    <DataLoader
      url={`/apps/${app.uuid}/visits/sources/${current}/visits`}
      params={params}
    >
      {(data) => {
        const series = [];
        const d = normalizeChartData(data.visits, params);
        series.push(prepareSerie(current, d));
        const sum = data.visits.reduce((acc, v) => acc + parseInt(v.count, 10), 0);
        return (
          <div>
            <SparkLine
              color={color}
              xaxis={data.days}
              data={series}
              params={params}
            />
            <SectionTitle>
              {numeral(sum).format('0,0a')}
              {' '}
Visits
            </SectionTitle>
          </div>
        );
      }}
    </DataLoader>
  </div>
);

export default SourceSparkline;
