import { useState, useEffect } from 'react';
import { client } from 'shared/api';
import { useDispatch } from 'react-redux';

const qs = params => Object.keys(params)
  .map(key => `${key}=${params[key]}`)
  .join('&');

export default (path, propOptions = {}) => {
  const defaultOptions = { method: 'get', params: {} };
  const {
    reload,
    dispatchAction,
    map,
    defaultResponse,
    ...requestOptions
  } = propOptions;
  const options = { ...defaultOptions, ...requestOptions };
  const dispatch = useDispatch();
  const [data, setData] = useState({
    response: defaultResponse,
    error: null,
    loading: !!path,
  });
  useEffect(() => {
    if (path) {
      setData({ ...data, error: null, loading: true });
      const url = `${path}${
        options.method === 'get' ? `?${qs(options.params)}` : ''
      }`;
      client()
        [options.method](url, options.params)
        .then((response) => {
          if (dispatchAction) {
            dispatch(dispatchAction(map ? map(response.data) : response.data));
          }

          setData({
            response: map ? map(response.data) : response.data,
            error: null,
            loading: false,
          });
        })
        .catch((error) => {
          console.log('error', error, error.response);
          setData({ response: null, error: error.response, loading: false });
        });
    }
  }, [path, JSON.stringify(options), reload]);
  return {
    ...data,
  };
};
