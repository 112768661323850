import React, { useState } from 'react';
import useFetch from 'hooks/useFetch';
import { prepareParams } from 'components/apps/Details/DataLoader';
import cn from 'classnames';
import useInterval from 'hooks/useInterval';

import Button from 'components/ui/Button';
import { usePageVisibility } from 'react-page-visibility';
import css from './style.module.scss';

export const CurrentUsers = ({ app, params }) => {
  const isVisible = usePageVisibility();

  const [reloadDate, setReloadDate] = useState(new Date().getTime());
  const { response } = useFetch(
    `/apps/${app.uuid}/visits/realtime`,
    {
      params: prepareParams({ ...params, reloadDate }),
    },
  );
  useInterval(() => {
    if (isVisible) {
      setReloadDate(new Date().getTime());
    }
  }, 10000);
  if (response && response.currentUsers > 0) {
    const users = response.currentUsers;
    return (
      <div className={cn(css.realtimeContainer)}>
        There
        {' '}
        {users > 1 ? `are ${users}` : 'is a'}
        {' '}
visitor
        {users > 1 && 's'}
        {' '}
on
        {' '}
        <b>{app.name}</b>
        {' '}
now.
        {' '}
        <Button link to={`/a/${app.uuid}/current`}>
          View
        </Button>
      </div>
    );
  }
  return null;
};

export default CurrentUsers;
