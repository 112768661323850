import React, { useState } from 'react';
import useFetch from 'hooks/useFetch';

import { normalizeChartData } from 'shared/visits';
import { Checkbox } from 'components/ui/Inputs';
import {
  prepareParams,
  Displayer,
} from 'components/apps/Details/DataLoader';
import css from './style.module.scss';
import VisitsChart from './Chart';

import SectionHeader from '../../../SectionHeader';

const dictionary = {
  sessions: 'Sessions',
  pageviews: 'Pageviews',
};

const prepareSerie = (name, { elements, days }, secondary) => ({
  name,
  type: secondary ? 'line' : 'line',
  data: elements,
  categories: days,
});

export const Visits = ({
  app, params, wideChart, onChangeDate,
}) => {
  // const [compare, toggleCompare] = useState(false);
  const [showUnique, toggleUnique] = useState(true);
  const { response, error, loading } = useFetch(`/apps/${app.uuid}/visits`, {
    params: prepareParams(params),
  });
  const {
    response: uniqueResponse,
    error: uniqueErr,
    loading: uniqueLoading,
  } = useFetch(showUnique && `/apps/${app.uuid}/visits`, {
    params: prepareParams({ ...params, group: { value: 'sessions' } }),
  });

  let data = {};
  const series = [];
  const csvData = () => [['date', 'visits']].concat(data.data);

  if (response) {
    data = normalizeChartData(response.visits, params);
    if (showUnique && uniqueResponse) {
      series.push(
        prepareSerie(
          'Unique Visits',
          normalizeChartData(uniqueResponse.visits, params),
          true,
        ),
      );
    }
    series.push(prepareSerie('Pageviews', data));
  }

  return (
    <div className={css.visits}>
      <SectionHeader
        title="Visits"
        subtitle={`All pageviews and unique visitors for ${app.name}`}
        download
        downloadData={csvData}
        downloadFileName={`${app.name} - ${dictionary[params.group.value]}`}
      />
      <Displayer
        height="265px"
        loading={loading || uniqueLoading}
        error={error || uniqueErr}
      >
        <VisitsChart
          onChangeDate={onChangeDate}
          xaxis={data.days}
          data={series}
          wideChart={wideChart}
          title={dictionary[params.group.value]}
          params={params}
        />
      </Displayer>

      {/* <Checkbox
        name="compare"
        selected={compare}
        onChange={val => toggleCompare(val)}
      >
        Compare with previous period
      </Checkbox> */}
      <Checkbox
        name="unique"
        selected={showUnique}
        onChange={val => toggleUnique(val)}
      >
        Show unique visits
      </Checkbox>
    </div>
  );
};

export default Visits;
