import React from 'react';
import cn from 'classnames';
import css from './style.module.scss';

const Loading = ({ fullPage, style = {}, height }) => {
  if (height) {
    style.height = height;
  }
  return (
    <div style={style} className={cn(css.loading, fullPage && css.fullPage)}>
      Loading...
    </div>
  );
};

export default Loading;
