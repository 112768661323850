import React from 'react';
import Button from 'components/ui/Button';
import { useDispatch } from 'react-redux';
// import { toast } from 'reducers/toasts';
// import { setApp } from 'reducers/apps';
import { SectionTitle, Text } from 'components/ui/Typography';
import api from './api';
import css from './style.module.scss';

const EditForm = ({ app }) => {
  // const dispatch = useDispatch();

  // const archiveApp = async () => {
  //   if (window.confirm('Do you want to archive this app?')) {
  //     const response = await api.archiveApp(app.uuid);
  //     if (!response.error) {
  //       dispatch(setApp(response));
  //       dispatch(toast({ message: 'App saved.' }));
  //     }
  //   }
  // };

  const deleteApp = async () => {
    if (window.confirm('Are you sure?')) {
      const response = await api.deleteApp(app.uuid);
    }
  };
  return (
    <div className={css.danger}>
      <SectionTitle className={css.title}>Danger Zone</SectionTitle>
      <Text>Please procede with caution.</Text>
      {/* <Text>
        When you archive an app, it won't compare in your homepage. It will
        continue to store visits to your website.
      </Text>
      <Button onClick={archiveApp}>
        {app.archivedAt ? "Move to homepage" : `Archive ${app.name}`}
      </Button> */}

      <SectionTitle className={css.deleteApp}>
Delete
        {' '}
        {app.name}
      </SectionTitle>
      <Text>
        This app will be deleted and you won't be able to access it anymore.
        Please be sure of what are you doing as there is no turning back.
      </Text>
      <Button danger small onClick={deleteApp}>
        Delete
        {' '}
        {app.name}
      </Button>
    </div>
  );
};

export default EditForm;
