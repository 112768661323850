import React from "react";
import { Input } from "components/ui/Inputs";
import Button from "components/ui/Button";
import { Formik, Field } from "formik";
import { route } from "preact-router";
import { useDispatch } from "react-redux";
import { SectionTitle } from "components/ui/Typography";
import ModalBox from "components/ui/ModalBox";
import { toast } from "reducers/toasts";
import useFetch from "hooks/useFetch";
import css from "./style.module.scss";
import { resetPassword } from "./api";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const token = window.location.href.split("/").pop();
  const { loading, error } = useFetch(`/users/password/${token}`, {});
  if (loading) {
    return <div>Loading</div>;
  }
  if (error) {
    dispatch(toast({ message: "Token not valid or expired." }));
    route("/");
    return null;
  }

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    await resetPassword(token, values).then((response) => {
      setTimeout(() => {
        route("/login");
      }, 1000);
    });
  };
  return (
    <ModalBox
      withHeader
      onClose={() => {
        route("/");
      }}
      animate
    >
      <SectionTitle>Reset your password</SectionTitle>
      <div className={css.form}>
        <Formik
          initialValues={{ password: "" }}
          onSubmit={onSubmit}
          render={({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                component={Input}
                name="password"
                type="password"
                label="Your new password"
              />
              <Button type="submit" disabled={isSubmitting}>
                Change Password
              </Button>
            </form>
          )}
        />
      </div>
    </ModalBox>
  );
};

export default ResetPassword;
