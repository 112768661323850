
import MediaQuery from 'react-responsive';
import React from 'react';


const maxMobile = 800;
const minTablet = 800;
const maxTablet = 960;
const minHuge = 1500;


export const Small = ({ children }) => <MediaQuery maxDeviceWidth={maxMobile}>{children}</MediaQuery>;

export const TabletOrDesktop = ({ children }) => <MediaQuery minDeviceWidth={maxMobile}>{children}</MediaQuery>;
