import React from 'react';
import { SectionTitle, Text } from 'components/ui/Typography';
import Button from 'components/ui/Button';
import useUser from 'hooks/useUser';
import css from './style.module.scss';

export const Info = ({ openNewPanel }) => {
  const user = useUser();
  return (
    <div className={css.info}>
      <SectionTitle>What is an event?</SectionTitle>
      <Text>
          You can add Events to custom actions done by your users on your
          website.
        {' '}
        <br />
          Everytime a user does an action,
        {' '}
        <b>Metrical</b>
        {' '}
can track that Event and
          displays it here.
        <br />
        <br />
          Here are some examples of Events that you can track:
        <ul>
          <li>When a user clicks on a specific button;</li>
          <li>When a user completes a payment;</li>
          <li>When a user visits a specific page (ex: /thank-you, /order-completed,...)</li>
          <li>When a user downloads something (a pdf, an e-book, etc...).</li>
        </ul>
      </Text>

      {/* <SectionTitle>How to add an event?</SectionTitle>
    <Text>
          It's really easy: just call in your Javascript
      <pre>
        <code>window.metrical.trackEvent("type_of_event")</code>
      </pre>
          With the type of event that you added when you created the event here
          in
      {' '}
      <b>Metrical</b>
.
    </Text> */}
      <SectionTitle>Ready?</SectionTitle>
      <Text>
          You can create as many Events as you want and compare them together!
        <br />
        <Button disabled={user.demo} secondary onClick={() => openNewPanel(true)}>Create your first event</Button>
      </Text>

    </div>
  );
};

export default Info;
