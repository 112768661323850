import React, { useMemo } from 'react';
import { normalizeChartData } from 'shared/visits';
import { route } from 'preact-router';
import { SectionTitle, SectionSubTitle } from 'components/ui/Typography';
import Favicon from 'components/ui/Favicon';
import numeral from 'numeral-es6';
import cn from 'classnames';
import css from './style.module.scss';
import Sparkline from './Sparkline';

const AppItem = ({
  uuid,
  name,
  favicon,
  visits,
  totalVisits,
  owner,
  userRole,
  animate,
}) => {
  const { empty, data } = useMemo(() => normalizeChartData(visits), [
    JSON.stringify(visits),
  ]);
  const onClick = () => route(`/a/${uuid}`);
  return (
    <div onClick={onClick} className={css.appItem}>
      <div className={css.top}>
        <div className={css.appName}>
          <SectionTitle className={cn(css.ellipsis, css.title)}>
            {name}
          </SectionTitle>
          <SectionSubTitle className={cn(css.ellipsis, css.subtitle)}>
            <b>
              {numeral(totalVisits).format('0,0a')}
              {' '}
pageviews
            </b>
            {' '}
last 2 weeks
          </SectionSubTitle>
        </div>
        <Favicon
          url={favicon}
          miniImage={userRole !== 'owner' && owner.avatar}
          size="small"
          className={css.favicon}
        />
      </div>

      {/* <MiniInfo>{numeral(totalVisits).format('0,0a')}</MiniInfo> */}

      <div className={css.sparkline}>
        <Sparkline animate={animate} empty={empty} visits={data} />
      </div>
    </div>
  );
};

export default AppItem;
