import React, { useState, useEffect } from 'react';
import { Title, SectionTitle, Text } from 'components/ui/Typography';
import cn from 'classnames';
import Button from 'components/ui/Button';
import { differenceInCalendarDays } from 'date-fns';
import css from './style.module.scss';

export const TrialBox = ({ subscription }) => (
  <div className={css.trialBox}>
    <div className={css.content}>
      <SectionTitle>
        {differenceInCalendarDays(subscription.trialEndsAt, new Date())}
        {' '}
days left for your
        trial.
        {' '}
        <Button link arrow to="/subscribe" className={css.link}>
          Subscribe now
        </Button>
      </SectionTitle>
      {/* <Text>Upgrade now and add your own websites to Metrical</Text> */}
    </div>
  </div>
);

export const TrialEnded = () => (
  <div className={css.trialEndedBox}>
    <div className={css.content}>
      <SectionTitle>Trial Ended.</SectionTitle>
      <Text>Please subscribe to continue using Metrical.</Text>
      <Text>In the meanwhile, you can only view and download your data. Visits won't be saved until you upgrade.</Text>
      <Button to="/subscribe">
        Subscribe
      </Button>
    </div>
  </div>
);


export const BetaUser = () => (
  <div className={css.trialEndedBox}>
    <div className={css.content}>
      <SectionTitle>Thank you for being one of the first users of Metrical.</SectionTitle>
      <Text>You can continue to use your free account as long as you want, with 50.000 visits and 2 websites.</Text>
      <Text>If you need more, you can always subscribe with the little button below 👇.</Text>
      <Button to="/subscribe">
        Subscribe
      </Button>
    </div>
  </div>
);
