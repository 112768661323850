import React from 'react';
import Header from 'components/ui/Header';
import Footer from 'components/ui/Footer';
import css from './style.module.scss';

export const Page = ({ children, demo }) => (
  <div className={css.page}>
    <Header demo={demo} />
    <div className={css.content}>
      {children}
      <Footer />
    </div>
  </div>
);

export default Page;
