import React from "react";
import { Input } from "components/ui/Inputs";
import Button from "components/ui/Button";
import { Formik, Field } from "formik";
import { route } from "preact-router";
import { SectionTitle } from "components/ui/Typography";
import ModalBox from "components/ui/ModalBox";
import css from "./style.module.scss";
import { forgotPassword } from "./api";

const SignInForm = () => {
  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    await forgotPassword(values);
    route("/");
  };
  return (
    <ModalBox
      withHeader
      animate
      onClose={() => {
        route("/");
      }}
    >
      <SectionTitle>Forgot your password?</SectionTitle>
      <div className={css.form}>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={onSubmit}
          render={({ handleSubmit, isSubmitting, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                component={Input}
                name="email"
                type="email"
                label="Your Email"
              />
              <Button type="submit" disabled={isSubmitting}>
                Send Instructions
              </Button>
            </form>
          )}
        />
      </div>
    </ModalBox>
  );
};

export default SignInForm;
