import React from 'react';
import SectionBarVisualizer from '../SectionBarVisualizer';

const normalizeData = (data, addFilter) => data.countries.reduce(
  (acc, el) => {
    acc.maxValue = acc.maxValue > parseInt(el.count) ? acc.maxValue : parseInt(el.count);
    acc.items.push({
      value: el.country,
      count: el.count,
      onClick: () => {
        addFilter('country', el.country, {
          value: el.country,
          name: 'Country',
        });
      },
    });
    return acc;
  },
  { items: [], max: 1, columns: ['language', 'visits'] },
);

export const Countries = ({
  app, params, addFilter, color,
}) => (
  <div>
    <SectionBarVisualizer
      withHeader
      header={{
        title: 'Countries',
        subtitle: 'Where are your visitors coming from?',
      }}
      downloadFileName={`${app.name} - Countries`}
      url={`/apps/${app.uuid}/visits/countries`}
      params={params}
      normalizer={normalizeData}
      showPages
      name="country"
      addFilter={addFilter}
      barColor={color}
    />
  </div>
);

export default Countries;
