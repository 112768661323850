import React from 'react';
import cn from 'classnames';
import Icon from 'components/ui/Icon';
import useKey from 'hooks/useKey';
import { useAnimations } from 'hooks/useUser';
import { Title, SectionTitle } from 'components/ui/Typography';
import usePortal from 'hooks/usePortal';
import { createPortal } from 'react-dom';
import { isPhone } from 'shared/utils';
import { route } from 'preact-router';
import Animator from 'components/ui/Animator';
import css from './style.module.scss';

export const Portal = ({ children }) => {
  const target = usePortal('modals');
  return createPortal(children, target);
};

const Panel = ({
  children,
  className,
  title,
  scroll,
  withShadow = false,
  closable = true,
  size = 'medium',
  color,
  backButton,
  onClose,
}) => {
  const small = isPhone();
  const onCloseEvent = () => {
    backButton && route(backButton);
    closable && onClose && onClose();
  };
  useKey('esc', () => {
    onCloseEvent();
  });

  return (
    <Portal>
      <div className={cn(css.modal, scroll && css.scroll)}>
        <div className={cn(css.inner, css[`${size}_size`], className)}>
          <Animator
            className={cn(
              css.modalContent,
              color,
              scroll && css.modalContentScroll,
            )}
          >


            {closable && (
              <div className={css.closeButton} onClick={onCloseEvent}>
                <Icon name="Close" className={css.close} />
              </div>
            )}

            <div className={cn(css.content, css.padding, css.fullWidth)}>
              {title && (
              <Title className={css.title}>{title}</Title>
              )}
              {children}
            </div>
          </Animator>
        </div>
      </div>
    </Portal>
  );
};

export default Panel;


// import React from 'react';
// import cn from 'classnames';
// import Icon from 'components/ui/Icon';
// import {
//   animated,
//   useSpring,
// } from 'react-spring';
// import useKey from 'hooks/useKey';
// import { useAnimations } from 'hooks/useUser';
// import { SectionTitle } from 'components/ui/Typography';

// import Animator from 'components/ui/Animator';
// import usePortal from 'hooks/usePortal';
// import { createPortal } from 'react-dom';
// import css from './style.module.scss';

// const Portal = ({ children }) => {
//   const target = usePortal('modals');
//   return createPortal(children, target);
// };

// const Panel = ({
//   children,
//   className,
//   title,
//   withShadow = false,
//   closable = true,
//   size = 'small',
//   onClose,
// }) => {
//   useKey('esc', () => {
//     closable && onClose();
//   });
//   const openProps = useSpring({
//     config: { duration: 200 },
//     from: {},
//     to: {},
//   });
//   return (
//     <Portal>
//       <animated.div
//         className={cn(css.modal)}
//         style={openProps}
//       >
//         <div className={cn(css.inner, withShadow ? css.withShadow : '', className)}>
//           <Animator
//             className={cn(css.modalContent, css[size])}
//           >
//             {closable && (
//             <div className={css.closeButton} onClick={onClose}>
//               <Icon name="Close" className={css.close} />
//             </div>
//             )}
//             {title
//             && (
//             <div className={css.header}>
//               <SectionTitle className={css.title}>{title}</SectionTitle>
//             </div>
//             )
// }
//             {children}
//           </Animator>
//         </div>
//       </animated.div>
//     </Portal>
//   );
// };

// export default Panel;
