import { client } from 'shared/api';
import { store } from 'reducers';
import { toast } from 'reducers/toasts';
import { addEvent } from 'reducers/app';
import { trackEvent } from 'shared/track';

const createEvent = async (uuid, values) => {
  const params = { ...values };
  if (params.page) {
    params.type = `__visit__${values.page}__${(new Date()).getTime()}`;
  }
  const response = await client().post(`/apps/${uuid}/events`, params);
  if (!response.data.errors) {
    store.dispatch(addEvent(response.data));
    store.dispatch(toast({ message: 'Event created.' }));
    trackEvent('new_event');
  }

  return response.data;
};

export default {
  createEvent,
};
