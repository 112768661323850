import React from 'react';
import ApexChart from 'react-apexcharts';
import { useColors } from 'hooks/useTheme';
import css from './style.module.scss';

const EmptyChart = () => <div className={css.empty} />;
const Sparkline = ({ visits, empty, animate }) => {
  const colors = useColors();
  const values = visits.map(v => v[1]);
  const max = Math.max(...values);
  // const min = Math.min(...values) || -1;
  const options = {
    chart: {
      animations: {
        enabled: animate,
      },
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      // min,
      max: max * 1.3,
    },
    colors: [colors.primaryColor100],
    tooltip: {
      enabled: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      marker: {
        show: false,
      },
    },
  };
  const series = [
    {
      name: 'visits',
      data: values,
    },
  ];
  if (empty) {
    return <EmptyChart />;
  }
  return (
    <ApexChart options={options} series={series} type="area" height="70px" />
  );
};

export default Sparkline;
