import React from 'react';
import css from './style.module.scss';
import SectionBarVisualizer from '../SectionBarVisualizer';

const normalizeData = (data, addFilter) => data.languages.reduce(
  (acc, el) => {
    acc.maxValue = acc.maxValue > parseInt(el.count) ? acc.maxValue : parseInt(el.count);
    acc.items.push({
      value: el.name || '-',
      count: el.count,
      onClick: () => {
        addFilter('language', el.language, {
          value: el.name,
          name: 'Language',
        });
      },
    });
    return acc;
  },
  { items: [], max: 1, columns: ['language', 'visits'] },
);

export const Languages = ({
  app, params, addFilter, color,
}) => (
  <div className={css.referrals}>
    <SectionBarVisualizer
      withHeader
      header={{
        title: 'Languages',
        subtitle: "What's the most speaked language around your visitors?",
      }}
      downloadFileName={`${app.name} - Languages`}
      url={`/apps/${app.uuid}/visits/languages`}
      params={params}
      normalizer={normalizeData}
      showPages
      name="language"
      addFilter={addFilter}
      barColor={color}
    />
  </div>
);

export default Languages;
