import React, { useRef } from 'react';
import ApexChart from 'react-apexcharts';

import useComponentSize from '@rehooks/component-size';
import { format } from 'date-fns';
import { useColors } from 'hooks/useTheme';
import numeral from 'numeral-es6';
import { useAnimations } from 'hooks/useUser';
import css from './style.module.scss';

const createTooltip = colors => ({
  series, dataPointIndex, w,
}) => {
  const tooltip = ['<div class="apex-tooltip">'];
  const firstDate = w.config.series[0].categories[dataPointIndex];
  tooltip.push('<div class=\'date\'>Last 30 minutes</div><div class="body">');
  w.config.series.forEach((serie, index) => {
    tooltip.push(`<div class="group">
      <div class="row">
        <div class="point" style="background: ${colors[index]}"></div>
        <div class="title">${format(firstDate, 'hh:mm')}</div>
        <div class="value">
          ${series[index][dataPointIndex]}
        </div>
      </div>
      </div>`);
  });
  tooltip.push('</div></div>');

  return tooltip.join('');
};

const Chart = ({
  data, params, title, type, xaxis, animate,
}) => {
  const chartType = type;
  const ref = useRef(null);
  const colors = useColors();
  const animateSettings = useAnimations();
  const { width } = useComponentSize(ref);
  const chartHeight = '300px';
  // const maxValue = data.reduce((max, v) => (max < v.count ? v.count : max), 0);
  const chartOpts = {
    colors: [
      colors.secondaryColor10,
      colors.warnColor100,
      colors.secondaryColor10,
      colors.accentColor10,
    ],
  };

  const options = {
    chart: {
      animations: {
        enabled: animate && animateSettings,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
      },
    },
    grid: {
      borderColor: 'transparent',
      row: {
        colors: ['rgba(66, 217, 200, 0.07)', 'transparent'], // takes an array which will be repeated on columns
      },
      padding: {
        right: 14,
      },
    },
    fill: {
      colors: chartOpts.colors,
      type: 'solid',
      // opacity: [0.5, 0.3],
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    colors: chartOpts.colors,
    markers: {
      size: 3,
      colors: chartOpts.colors,
      strokeColor: chartOpts.colors,
      fillOpacity: 0,
      hover: {
        size: 5,
      },
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      categories: xaxis,
      labels: { show: true },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    yaxis: {
      min: 0,
      // max: maxValue + maxValue * 0.01,
      forceNiceScale: true,
      tickAmount: 5,
      labels: {
        formatter(val) {
          return numeral(val).format('0.00');
        },
      },
    },
    zoom: false,
    tooltip: {
      custom: createTooltip(chartOpts.colors),
      y: {
        formatter(val) {
          return `${val} ${title}`;
        },
      },
      x: {
        formatter(val) {
          const f = params.timeSection === 'day'
            ? 'DD-MM-YYYY HH:mm'
            : 'DD-MM-YYYY HH:mm';
          return format(val, f);
        },
      },
    },
  };

  return (
    <div ref={ref} className={css.chartContainer}>
      {width > 0 && (
        <ApexChart
          options={options}
          series={data}
          type={chartType}
          // width={chartWidth}
          height={chartHeight}
        />
      )}
    </div>
  );
};

export default Chart;
