import React from "react";
import cn from "classnames";
import { route } from "preact-router";
import css from "./style.module.scss";

const Tab = ({ id, name, selected, onSelect, link }) => (
  <div
    className={cn(css.tab, selected && css.selected)}
    onClick={() => (link ? route(link) : onSelect(id))}
  >
    <div className={css.text}>{name}</div>
    <div className={css.bar} />
  </div>
);

const Tabs = ({ tabs, selected, onSelect, className }) => (
  <div className={cn(css.tabs, className)}>
    {tabs.map((t) => (
      <Tab key={t.id} {...t} onSelect={onSelect} selected={selected === t.id} />
    ))}
  </div>
);

export default Tabs;
