import React, { useState, useEffect } from 'react';
import { route } from 'preact-router';
import Redirect from 'components/ui/Redirect';
import { Title, SectionTitle, Text } from 'components/ui/Typography';
import useUser from 'hooks/useUser';
import cn from 'classnames';
import Button from 'components/ui/Button';
import Plans from 'shared/plans';
import api from 'shared/api';
import useStripe from 'hooks/useStripe';
import { Checkbox } from 'components/ui/Inputs';
import css from './style.module.scss';
// import CheckoutForm from "./Checkout";

const Pricings = ({ selected, onChoose }) => (
  <div className={css.pricings}>
    {Plans.map(p => (
      <Checkbox
        key={p.id}
        onLine
        onChange={() => !(p.id === selected) && onChoose(p.id)}
        selected={p.id === selected}
        className={cn(css.plan, selected && css.selected)}
      >
        <Title className={css.title}>{p.title}</Title>
        <div className={css.price}>
          {p.price}
          <small>{p.afterPrice}</small>
        </div>
      </Checkbox>


    ))}
  </div>
);

export default ({ location, close = 'false' }) => {
  const user = useUser();
  useStripe();
  const [choosedPlan, setChoosedPlan] = useState(Plans[0].id);
  if (user.subscription.status === 1) {
    // return <SuccessMessage/>
    return <Redirect to="/" />;
  }
  const onSubmit = async () => {
    const stripe = window.Stripe(process.env.PREACT_APP_STRIPE_KEY);
    const session = await api().post('/subscriptions/sessions', {
      priceId: choosedPlan,
    });
    stripe
      .redirectToCheckout({
        sessionId: session.data.sessionId,
      })
      .then((result) => {
        console.log('error');
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={css.pricingPage}>
      <div className={css.left}>
        <div>
          <Title className={css.mainTitle}>Subscribe</Title>
          <SectionTitle className={css.pricingTitle}>
          Select your plan
          </SectionTitle>
          <Text>
          You will be redirected to our secure page on
            {' '}
            <Button link to="https://stripe.com" external>
            Stripe
            </Button>
          .
          </Text>

          <Pricings onChoose={setChoosedPlan} selected={choosedPlan} />
          <Button big onClick={() => onSubmit()}>Subscribe now</Button>
          <br />
          <br />
          <Button link to="/">Back to homepage</Button>
        </div>
      </div>
      <div className={css.right}>
        <div className={css.features}>
          <p>✨Perks of being subscribed to Metrical:</p>
          <ul>
            <li>
Up to
              {' '}
              <b>50.000</b>
              {' '}
visits every month;
            </li>
            <li>
You can add as many websites to track as you want,
              {' '}
              <b>we won't judge</b>
;
            </li>
            <li>Share them with your colleagues, or friends, or whoever you want;</li>
            <li>Download your data whenever you want in CSV format;</li>
            <li>
Support
              {' '}
              <Button link external to="https://metrical.xyz/about">2 awesome makers</Button>
              {' '}
that are trying to pay their rent every month.
            </li>
          </ul>
          <p>Cons of being subscribed to Metrical:</p>
          <ul>
            <li>None found (so far).</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
