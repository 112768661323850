import React from 'react';
import { Link, Router } from 'preact-router';
import Navigation from 'components/ui/Header/Navigation';
import useUser from 'hooks/useUser';
import Button from 'components/ui/Button';
import useLocation from 'hooks/useLocation';
import css from './style.module.scss';

const demoAvatar = 'https://randomuser.me/api/portraits/women/44.jpg';

export const UserAvatar = ({ demo, user }) => (
  <Link className={css.avatar} href={demo ? '/demo/register' : '/settings'}>
    <img src={!demo ? user.avatar : demoAvatar} />
  </Link>
);

export const Header = ({ location }) => {
  const page = new URL(window.location);
  const user = useUser();
  const { pathname } = useLocation();
  const demo = page.pathname.startsWith('/demo');
  const isAppPage = page.pathname.startsWith('/a/');
  const appUUID = isAppPage && page.pathname.split('/')[2];
  return (
    <div className={css.header}>
      <Button
        link
        className={css.logoDesktop}
        to={demo ? 'https://metrical.xyz' : '/'}
      >
        <img
          src={`${process.env.PREACT_APP_URL}/assets/metrical.logo.small.png`}
          alt="Metrical Logo"
          className={css.logoImage}
        />
      </Button>
      {isAppPage || demo}
      <Navigation demo={demo} uuid={appUUID} path={page.pathname} />
      {(user.authenticated || demo) && <UserAvatar demo={demo} user={user} />}
    </div>
  );
};

export default Header;
