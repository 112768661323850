import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { removeToast } from 'reducers/toasts';
import css from './style.module.scss';

const Toast = ({
  onClick, children, important, duration = 5000, id,
}) => {
  const [alreadyHidden, hide] = useState(false);
  const dispatch = useDispatch();
  const onClickHandler = () => {
    if (onClick) {
      onClick();
      hideToast();
    }
  };
  const hideToast = () => {
    if (!alreadyHidden) {
      dispatch(removeToast(id));
      hide();
    }
  };
  useEffect(() => {
    let timeout;
    if (duration !== -1) {
      timeout = setTimeout(hideToast, duration);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);
  return (
    <div onClick={onClickHandler} className={cn(css.toast, important ? css.important : '')}>
      {children}
    </div>
  );
};

export default Toast;
