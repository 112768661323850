import React, { useEffect, useState } from 'react';
import Page from 'components/ui/Page';
import AppDetails from 'components/apps/Details';
import Home from 'components/apps/Index';
import Subscribe from 'components/Subscribe';
import SubscribeSuccess from 'components/Subscribe/Success';
import Settings from 'components/Settings';
import SignIn from 'components/auth/SignIn';
import DemoPage from 'components/Demo';
import Register from 'components/auth/Register';
import ForgotPassword from 'components/auth/ForgotPassword';
import ResetPassword from 'components/auth/ForgotPassword/Reset.js';
import { Router } from 'preact-router';
import Redirect from 'components/ui/Redirect';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { updateUser } from 'reducers/user';
import { client } from 'shared/api';
import { store } from 'reducers';
import Loading from 'components/ui/Loading';
import ToastManager from 'components/ui/Toast';
import { setRoutePath } from 'reducers/ui';
import * as Sentry from '@sentry/browser';
import './style.scss';

require('typeface-nunito-sans');

Sentry.init({ dsn: process.env.PREACT_APP_SENTRY_DSN });

const saveTimezone = async (user, dispatch) => {
  user = await client().post('/users/me/edit', {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  dispatch(updateUser(user));
};

const UserWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    client()
      .get('users/me')
      .then((response) => {
        if (response.status === 200) {
          const user = response.data;
          dispatch(updateUser(user));
          if (!user.timezone) {
            saveTimezone(user, dispatch);
          }
        }
        setLoaded(true);
      })
      .catch(error => setLoaded(true));
  }, []);
  // return <Loading fullPage />
  if (loaded) {
    return children;
  }
  return <Loading fullPage />;
};

const Route = ({
  auth = false,
  redirectTo = '/',
  noAuth = false,
  authBranch = false,
  path,
  component,
  children,
  async = '',
  ...props
}) => {
  const user = useSelector(state => state.user);
  let Component = component;
  if (authBranch) {
    Component = component[user.authenticated ? 0 : 1];
  }
  if ((noAuth && user.authenticated) | (auth && !user.authenticated)) {
    return <Redirect to={redirectTo} />;
  }

  return <Component path={path} {...props} />;
};

const MetricalRouter = () => (
  <Page default>
    <Router onChange={(e) => {
      store.dispatch(setRoutePath(e.url));
      window.scrollTo(0, 0);
    }}
    >
      <Route auth path="/a/:appId/:tab?" component={AppDetails} />
      <Route auth path="/subscribe" component={Subscribe} />
      <Route auth path="/subscribe/success" component={SubscribeSuccess} />
      <Route auth path="/settings" component={Settings} />
      <Route noAuth path="/demo/:tab?" component={DemoPage} />
      {/* <Route noAuth path="/register" component={DemoRegister}/> */}
      {/* </Route> */}
      <Route noAuth path="/login" component={SignIn} />
      <Route noAuth path="/register" component={Register} />
      <Route noAuth path="/password/forgot" component={ForgotPassword} />
      <Route noAuth path="/password/reset/:token" component={ResetPassword} />
      <Route authBranch path="/:x?/:y?/:z?" component={[Home, SignIn]} />

    </Router>
  </Page>
);

const App = () => (
  <Provider store={store}>
    <UserWrapper>
      <MetricalRouter />
      <ToastManager />
    </UserWrapper>
  </Provider>
);

export default App;
