import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import { Input } from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import Panel from 'components/ui/Panel';
import { SectionTitle, Text } from 'components/ui/Typography';
import { route } from 'preact-router';
import { formatWebsite } from 'shared/utils';
import ScriptViewer from 'components/ScriptViewer';
import * as Yup from 'yup';
import { store } from 'reducers';
import { toast } from 'reducers/toasts';
import { trackEvent } from 'shared/track';
import { createApp } from './api';
import css from './style.module.scss';

const FAQ = () => (
  <div className={css.faqWrapper}>
    <SectionTitle>What happens after I create a new application?</SectionTitle>
    <Text>
      We will give you a
      {' '}
      <code>{'<script>'}</code>
      {' '}
tag to insert inside your
      HTML code. That’s it. Easy, right?
    </Text>
    <SectionTitle>But what if I’m using a JS framework?</SectionTitle>
    <Text>
      Metrical automatically tracks all URL's changes made by
      {' '}
      <code>history.pushState</code>
, so don't worry about it.
    </Text>
  </div>
);

const applicationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  website: Yup.string().required('Required').url('Is this an url?'),
});

export default ({ children }) => {
  const [showScript, setShowScript] = useState(undefined);
  const onSubmit = async (values, actions) => {
    if (actions.validateForm()) {
      actions.setSubmitting(true);
      const website = formatWebsite(values.website);
      const response = await createApp({ name: values.name, website });
      if (!response.error) {
        setShowScript(response);
        trackEvent('add_new_website');
      } else {
        actions.setSubmitting(false);
        if (response.error === 'apps_exceeded') {
          route('/subscribe', {
            close: true,
            onClose: () => {
              window.history.back();
            },
            reason: 'apps_exceeded',
          });
          // store.dispatch(
          //   toast({ message: 'You already have 2 applications.' }),
          // );
        }
      }
    }
  };
  return (
    <Panel
      title={showScript ? '' : 'Add a new website'}
      size="medium"
      onClose={() => {
        route('/');
      }}
    >
      <div className={css.createApp}>
        <div className={css.formWrapper}>
          {!showScript ? (
            <div className={css.formContainer}>
              <Formik
                validationSchema={applicationSchema}
                initialValues={{ name: '', website: '' }}
                onSubmit={onSubmit}
                render={({
                  handleSubmit, isSubmitting, values, errors,
                }) => (
                  <form onSubmit={handleSubmit} className={css.form}>
                    <Field
                      errors={errors.name}
                      autofocus
                      placeholder="Dundler Miffin Paper Company, Inc"
                      component={Input}
                      name="name"
                      type="text"
                      label="Application Name"
                    />
                    <Field
                      errors={errors.website}
                      placeholder="https://dundermifflinpaper.com"
                      component={Input}
                      name="website"
                      type="text"
                      description="Add also http:// or https:// before your URL"
                      label="Website URL"
                    />
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? 'Adding your website...' : 'Add website'}
                    </Button>
                  </form>
                )}
              />
              <div className={css.faq}>
                <FAQ />
              </div>
            </div>
          ) : (
            <ScriptViewer app={showScript} newApp />
          )}
        </div>
      </div>
    </Panel>
  );
};
