import React, { useState } from 'react';
import { SectionSubTitle } from 'components/ui/Typography';
import Loading from 'components/ui/Loading';
import { prepareParams } from 'components/apps/Details/DataLoader';
import useFetch from 'hooks/useFetch';
import HorizontalBarChart from './HorizontalBarChart';
import css from './style.module.scss';
import FiltersBar from './FiltersBar';
import SectionHeader from '../../../SectionHeader';

const pagesOffset = 10;

export const SectionBarVisualizer = ({
  url,
  name,
  params,
  normalizer,
  barColor,
  addFilter,
  title,
  header,
  withHeader,
  downloadFileName,
  animations,
  showFavicons,
  showLoader = true,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const p = { ...params, page: currentPage };

  const { response: data, loading } = useFetch(url, {
    params: prepareParams(p),
    defaultResponse: { items: [], maxValue: 0, columns: [] },
    map: response => normalizer(response, addFilter, params),
  });
  const { items: normalizedItems, maxValue, columns } = data;
  const items = normalizedItems.sort((a, b) => (parseInt(a.count) < parseInt(b.count) ? 1 : -1));
  const showLoading = showLoader && loading;
  const prepareCSV = () => [columns].concat(items.map(p => [p.link || p.value, p.count]));
  return (
    <div>
      {withHeader && (
        <SectionHeader title={header.title} subtitle={header.subtitle} />
      )}
      {title && (
        <SectionSubTitle className={css.listTitle}>{title}</SectionSubTitle>
      )}
      <FiltersBar
        name={name}
        page={currentPage}
        hasMoreResults={items.length === pagesOffset}
        downloadData={prepareCSV}
        downloadFileName={downloadFileName}
        hasFilter={addFilter}
        onChangePage={(prev) => {
          if (prev) {
            setCurrentPage(currentPage - 1);
          } else {
            setCurrentPage(currentPage + 1);
          }
        }}
      />
      {showLoading && <Loading />}
      {!showLoading && data && (
        <HorizontalBarChart
          showFavicons={showFavicons}
          indexOffset={currentPage * pagesOffset}
          items={items}
          barColor={barColor}
          animations={animations}
          maxValue={maxValue}
        />
      )}
    </div>
  );
};

export default SectionBarVisualizer;
