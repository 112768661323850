import React from 'react';
import useUser from 'hooks/useUser';
import { Formik, Field } from 'formik';
import { SectionTitle, Text } from 'components/ui/Typography';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'reducers/user';
import { Input, Dropdown } from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import timezones from 'shared/timezones-object';
import css from './style.module.scss';
import { saveUser } from '../api';

const listTimezones = timezones();

const SettingsData = () => {
  const user = useUser();
  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    await saveUser(values);
  };

  const onLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <div>
      <SectionTitle>General Settings</SectionTitle>
      <Formik
        initialValues={{
          name: user.name,
          email: user.email,
          timezone: user.timezone,
          animations: user.animations ? '1' : '0',
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className={css.form}>
            <Field
              component={Input}
              name="name"
              type="text"
              label="Your Name"
            />
            <Field
              component={Input}
              name="email"
              type="email"
              label="Your Email"
            />
            <Field
              component={Dropdown}
              name="timezone"
              type="text"
              label="Your Timezone"
              options={listTimezones}
            />
            <Field
              component={Dropdown}
              name="animations"
              type="text"
              label="Show animations"
              options={[
                { value: true, label: 'Yes, animations make me feel alive' },
                { value: false, label: 'No, I feel dizzy' },
              ]}
            />

            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
            <br />
            <br />
            <Text>
              You can change your profile picture on
              {' '}
              <Button link external to="https://gravatar.com">
                Gravatar
              </Button>
              .
            </Text>
          </form>
        )}
      />
      <div className={css.links}>
        <Button link onClick={onLogout}>
          Logout from this account
        </Button>
      </div>
      {/* <ThemeChooser /> */}
    </div>
  );
};

export default SettingsData;
