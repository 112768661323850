import React from 'react';
import useFetch from 'hooks/useFetch';
import Loading from 'components/ui/Loading';
import Error from 'components/ui/Error';
import { format } from 'date-fns';

export const Displayer = ({
  loading, error, children, height,
}) => {
  if (loading) {
    return <Loading height={height} />;
  }
  if (error) {
    return <Error />;
  }
  return children;
};

export const prepareParams = (params) => {
  const output = {};
  if (params.group) {
    output.group = params.group.value;
  }
  if (params.from) {
    output.from = format(params.from, 'YYYY-MM-DD HH:mm:00');
  }
  if (params.utmSource) {
    output.utmSource = params.utmSource.value;
  }
  if (params.utmMedium) {
    output.utmMedium = params.utmMedium.value;
  }
  if (params.utmCampaign) {
    output.utmCampaign = params.utmCampaign.value;
  }
  if (params.timeSection) {
    output.timeSection = params.timeSection;
  }
  if (params.to) {
    output.to = format(params.to, 'YYYY-MM-DD');
  }
  if (params.country) {
    output.country = params.country.value;
  }
  if (params.device) {
    output.device = params.device.value;
  }
  if (params.referrer) {
    output.referrer = params.referrer.value;
  }
  if (params.language) {
    output.language = params.language.value;
  }
  if (params.url) {
    output.url = params.url.value;
  }
  if (params.page) {
    output.page = params.page;
  }
  if (params.reload) {
    output.reload = params.reload;
  }
  return output;
};

export const DataLoader = ({
  url, noLoad, params = {}, children,
}) => {
  const { response, loading, error } = useFetch(url, {
    params: prepareParams(params),
  });
  if (error) {
    return <Error />;
  }

  if (!noLoad && loading) {
    return <Loading height="250" />;
  }
  return children(response, loading);
};

export default DataLoader;
