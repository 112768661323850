import React from 'react';
import cn from 'classnames';
import Icon from 'components/ui/Icon';
import { format } from 'date-fns';
import css from './style.module.scss';

export default ({
  amount, date, url, paid,
}) => (
  <div className={css.invoice}>
    <div className={css.date}>{format(date, 'MMM YYYY')}</div>
    <div className={cn(css.status, paid ? css.paid : '')}>{paid ? 'Paid' : 'Pending'}</div>
    <div className={css.amount}>
€
      {amount / 100}
    </div>
    <a target="_blank" href={url} rel="noopener noreferrer">
      <Icon size={24} name="Download" />
    </a>
  </div>
);
