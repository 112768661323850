import React, { useState, useEffect } from 'react';

import { Title, SectionTitle, Text } from 'components/ui/Typography';
import useUser from 'hooks/useUser';
import cn from 'classnames';
import Button from 'components/ui/Button';
import Plans from 'shared/plans';
import api from 'shared/api';
import { trackEvent } from 'shared/track';
import css from './style.module.scss';
// import CheckoutForm from "./Checkout";

export default ({ location, close = 'false' }) => {
  const user = useUser();
  const [plan, setPlan] = useState(Plans[0].id);
  // if (user.subscription.status === 1) {
  //   // return <SuccessMessage/>
  //   return <Redirect to="/" />;
  // }
  const onSubmit = async () => {
    const stripe = window.Stripe(process.env.PREACT_APP_STRIPE_KEY);
    const session = await api().post('/subscriptions/sessions', {
      priceId: plan,
    });
    stripe
      .redirectToCheckout({
        sessionId: session.data.sessionId,
      })
      .then((result) => {
        console.log('error');
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSubscription = (newUserData) => {
    setNewUser(newUserData);
    setSubscriptionFinished(true);
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://js.stripe.com/v3/';
    script.async = true;

    document.body.appendChild(script);
    trackEvent('new_subscription');
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={css.pricingPage}>
      <div>
        <Title>Thank you.</Title>
        <SectionTitle className={css.pricingTitle}>
          You are now ready to use Metrical in his full potential.
        </SectionTitle>
        <Button to="/">Return to Metrical</Button>
      </div>
    </div>
  );
};
