import { client } from 'shared/api';
import { store } from 'reducers';
import { toast } from 'reducers/toasts';

export const forgotPassword = async ({ email }) => {
  try {
    const response = await client().post('/users/password/reset', { email });
    store.dispatch(
      toast({
        message: 'You will receive an email with all password instructions',
      }),
    );
    return [response.data, null];
  } catch (err) {
    store.dispatch(
      toast({ message: 'Email or password not valid. Try again.' }),
    );
    return [null, err];
  }
};
export const resetPassword = async (token, { password }) => {
  try {
    const response = await client().post(`/users/password/${token}`, {
      password,
    });
    store.dispatch(toast({ message: 'Reset password successfull!' }));
    return [response.data, null];
  } catch (err) {
    store.dispatch(
      toast({ message: 'Email or password not valid. Try again.' }),
    );
    return [null, err];
  }
};

export default {
  forgotPassword,
  resetPassword,
};
