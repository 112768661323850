import { createAction, createReducer } from 'redux-act';

export const toast = createAction('toast');
export const removeToast = createAction('removeToast');

const defaultState = [];

export default createReducer(
  {
    [toast]: (state, payload) => {
      if (payload.id && state.find(t => t.id === payload.id)) {
        return state;
      }
      return [
        ...state,
        {
          id: payload.id || new Date().getTime(),
          message: payload.message,
          important: payload.important,
          duration: payload.duration,
          onClick: payload.onClick,
        },
      ];
    },
    [removeToast]: (state, payload) => state.filter(t => t.id !== payload),
  },
  defaultState,
);
