import { client } from 'shared/api';
import { store } from 'reducers';
import { setUser } from 'reducers/user';
import { toast } from 'reducers/toasts';

export const signIn = async ({ email, password }) => {
  try {
    const { data } = await client().post('/users/login', { email, password });
    if (data.status === 'LOGGED_IN') {
      store.dispatch(setUser(data.user));
    }
    return [data, null];
  } catch (err) {
    store.dispatch(
      toast({ message: 'Email or password not valid. Try again.' }),
    );
    return [null, err];
  }
};

export default {
  signIn,
};
