import React from 'react';
import { Input } from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import { Formik, Field } from 'formik';
import { Title, Text } from 'components/ui/Typography';
import { route } from 'preact-router';
import { useDispatch } from 'react-redux';
import { setUser } from 'reducers/user';
import { toast } from 'reducers/toasts';
import ModalBox from 'components/ui/ModalBox';
import trackEvent from 'shared/track';

import css from './style.module.scss';
import { register } from './api';

export default ({ demo = false }) => {
  const dispatch = useDispatch();
  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    const response = await register({ ...values });
    if (response.status === 'REGISTERED') {
      trackEvent('new_user');
      dispatch(setUser(response.user));
      route('/');
    }
    if (response.status === 'ERROR') {
      if (response.type === 'unique violation') {
        dispatch(
          toast({
            message:
              'This email is already used on Metrical. Maybe you want to login instead?',
          }),
        );
      }
    }
  };
  return (
    <ModalBox
      withHeader
      animate
      closable={demo}
      onClose={() => window.history.back()}
    >
      <div className={css.form}>
        <Title>
          {demo ? 'Want more? Register' : 'Welcome to Metrical! 🥳'}
        </Title>
        {demo && (
          <Text className={css.subtitle}>
            This is Metrical. Interested? Register now.
          </Text>
        )}
        {!demo && (
          <Text className={css.subtitle}>
            It's a pleasure to have you here.

          </Text>
        )}
        <Formik
          initialValues={{
            email: '',
            password: '',
            name: '',
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                component={Input}
                name="name"
                type="text"
                label="Your Name"
              />
              <Field
                component={Input}
                name="email"
                type="email"
                label="Your Email"
              />
              <Field
                component={Input}
                name="password"
                type="password"
                label="Your Password"
              />
              {/*
              <div className={css.links}>
                <Link to="/login">Login instead</Link>
              </div> */}
              <Button type="submit" disabled={isSubmitting}>
                Register & start trial
              </Button>
              <br />
              <br />
              <br />
              <br />
              <Text>
You will have 2 weeks of trial period where you can add up to 2 websites and track up to 10.000 visits.
                <br />
                <br />
After this period, you will need to register to Metrical to continue using it.

              </Text>
            </form>
          )}
        />
      </div>
    </ModalBox>
  );
};
