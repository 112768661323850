import { client } from 'shared/api';
import { store } from 'reducers';

import { updateUser } from 'reducers/user';
import { toast } from 'reducers/toasts';

export const cancelSubscription = async () => {
  try {
    const response = await client().delete('/subscriptions');
    store.dispatch(updateUser(response.data));
    store.dispatch(toast({ message: 'Your subscription is now canceled.' }));
    return [response.data, null];
  } catch (err) {
    store.dispatch(
      toast({
        message:
          'There was a problem connecting with Metrical. Try again later.',
      }),
    );
    return [null, err];
  }
};

export const saveUser = async (model) => {
  try {
    const response = await client().post('/users/me/edit', model);
    store.dispatch(updateUser(response.data));
    store.dispatch(toast({ message: 'Account updated.' }));
    return [response.data, null];
  } catch (err) {
    store.dispatch(
      toast({
        message:
          'There was a problem connecting with Metrical. Try again later.',
      }),
    );
    return [null, err];
  }
};

export default { saveUser, cancelSubscription };
