import React from 'react';
import { useTransition } from 'react-spring';
import { useAnimations } from 'hooks/useUser';
import css from './style.module.scss';
import SectionBarRow from '../SectionBarRow';

export const Empty = ({ barColor }) => (
  <div className={css.empty}>
    <div
      className={css.box}
      style={{ width: `${Math.random() * 40 + 50}%`, background: barColor }}
    />
    <div
      className={css.box}
      style={{ width: `${Math.random() * 40 + 50}%`, background: barColor }}
    />
    <div
      className={css.box}
      style={{ width: `${Math.random() * 40 + 50}%`, background: barColor }}
    />
    <div
      className={css.box}
      style={{ width: `${Math.random() * 40 + 50}%`, background: barColor }}
    />
    <div
      className={css.box}
      style={{ width: `${Math.random() * 40 + 50}%`, background: barColor }}
    />
    <div
      className={css.box}
      style={{ width: `${Math.random() * 40 + 50}%`, background: barColor }}
    />
    <div
      className={css.box}
      style={{ width: `${Math.random() * 40 + 50}%`, background: barColor }}
    />
    <div
      className={css.box}
      style={{ width: `${Math.random() * 40 + 50}%`, background: barColor }}
    />
  </div>
);

const HorizontalBarChart = ({
  items,
  barColor,
  maxValue,
  empty = false,
  indexOffset = 0,
  showPlaceholderIfEmpty = true,
  animations = true,
  showFavicons,
}) => {
  const animateSettings = useAnimations();
  const animate = animations && animateSettings;
  const transitionItems = useTransition(items, item => item.value, {
    unique: true,
    trail: 400 / items.length,
    from: item => (animate
      ? { opacity: 0, barWidth: '0%' }
      : { opacity: 1, barWidth: `${(item.count * 100) / maxValue}%` }),
    enter: item => ({
      opacity: 1,
      barWidth: `${(item.count * 100) / maxValue}%`,
    }),
    leave: { opacity: animate ? 0 : 1 },
  });
  if (showPlaceholderIfEmpty && (empty || items.length === 0)) {
    return <Empty barColor={barColor} />;
  }
  return (
    <div className={css.rows}>
      {transitionItems.map(({ item, key, props }, index) => (
        <SectionBarRow
          onClick={item.onClick}
          value={item.value}
          count={item.count}
          index={index + indexOffset}
          key={index}
          link={item.link}
          barColor={barColor}
          showFavicons={showFavicons}
          style={{ opacity: props.opacity }}
          percentage={props.barWidth}
        />
      ))}
    </div>
  );
};

export default HorizontalBarChart;
