import React, { useState } from 'react';
import Panel from 'components/ui/Panel';
import { Input } from 'components/ui/Inputs';
import { SectionTitle, Text } from 'components/ui/Typography';
import Button from 'components/ui/Button';
import cn from 'classnames';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import useUser from 'hooks/useUser';
import css from './style.module.scss';

import api from './api';

const eventSchema = (events, type) => {
  if (type === 'visit') {
    return Yup.object().shape({
      name: Yup.string().required('Required'),
      page: Yup.string().required('Required'),
    });
  }
  return Yup.object().shape({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required').notOneOf(
      events.map(e => e.type),
      'Already used',
    ).matches(/^\w*$/, 'Check again the format'),
  });
};

const EventType = ({
  selected, onSelect, title, text,
}) => (
  <button onClick={onSelect} type="button" className={cn(css.eventType, selected && css.eventTypeSelected)}>
    <div className={css.eventTypeTitle}>{title}</div>
    <Text className={css.eventTypeText}>{text}</Text>
  </button>
);


export const NewEvent = ({ onClose, events = [], app }) => {
  const user = useUser();
  const [type, setType] = useState();
  const onSubmit = async (values, actions) => {
    if (actions.validateForm() && !user.demo) {
      actions.setSubmitting(true);
      const response = await api.createEvent(app.uuid, values);
      if (!response.error) {
        onClose();
      } else {
        actions.setSubmitting(false);
      }
    }
  };
  return (
    <Panel
      title="New event"
      className={css.container}
      onClose={onClose}
      size="small"
    >
      <br />
      <br />
      <SectionTitle>What type of event do you want to add?</SectionTitle>

      <div className={css.types}>
        <EventType
          title="Normal Event"
          selected={type === 'event'}
          onSelect={() => { setType('event'); }}
          text="An event that you will track via code. Example: click of a button."
        />
        <EventType
          title="Page Visited"
          selected={type === 'visit'}
          onSelect={() => { setType('visit'); }}
          text="A specific page visited. Ex: /thanks.html"

        />
      </div>
      <div className={css.formContainer}>
        <Formik
          validationSchema={eventSchema(events, type)}
          initialValues={{ name: '', type: '' }}
          onSubmit={onSubmit}
          render={({
            handleSubmit, isSubmitting, errors,
          }) => (
            <form onSubmit={handleSubmit} className={css.form}>
              {type && (
                <>

                  <Field
                    errors={errors.name}
                    autofocus
                    placeholder="Click button to subscribe to newsletter"
                    component={Input}
                    name="name"
                    type="text"
                    label="Event Name"
                  />
                  {type === 'event' && (
                    <Field
                      errors={errors.type}
                      placeholder="click_button_subscribe_newsletter"
                      component={Input}
                      name="type"
                      type="text"
                      label="Event type"
                      description="This is the name that you will use to track the event in your code. Use characters, numbers, and underscore (_) only."
                    />

                  )}
                  {type === 'visit' && (
                    <>
                      <Field
                        errors={errors.type}
                        placeholder="/thank_you.html"
                        component={Input}
                        name="page"
                        type="text"
                        label="Page name"
                        description="Add the entire URL or the last part of the page that you want to track. ex: https://metrical.xyz/thank-you.html or /thank-you.html"
                      />
                      <Text className={css.textWarn}>We are going to check all your past visits and create events based on this as well.</Text>
                    </>
                  )}
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Adding your new event...' : 'Add event'}
                  </Button>
                </>

              )}

            </form>
          )}
        />

      </div>
    </Panel>

  );
};

export default NewEvent;
