import React from 'react';
import css from './style.module.scss';
import SectionBarVisualizer from '../SectionBarVisualizer';

const normalizeData = (data, addFilter) => data.pages.reduce(
  (acc, el) => {
    acc.maxValue = acc.maxValue > parseInt(el.count) ? acc.maxValue : parseInt(el.count);
    acc.items.push({
      link: el.url,
      value: el.path || '/',
      count: el.count,
      onClick: addFilter
        ? () => {
          addFilter('url', el.url, { value: el.path || el.url, name: 'Page' });
        }
        : undefined,
    });
    return acc;
  },
  { items: [], max: 1, columns: ['page', 'visits'] },
);

export const Pages = ({
  app,
  params,
  addFilter,
  color,
  title = 'Top Pages',
  realTime,
  subtitle = 'Which pages are the most visited?',
}) => (
  <div className={css.pages}>
    <SectionBarVisualizer
      withHeader
      header={{
        title,
        subtitle,
      }}
      downloadFileName={`${app.name} - Top Pages`}
      url={`/apps/${app.uuid}/visits/pages`}
      params={params}
      name="url"
      normalizer={normalizeData}
      showPages
      addFilter={addFilter}
      animations={!realTime}
      barColor={color}
    />
  </div>
);

export default Pages;
