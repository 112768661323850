import axios from 'axios';
import Cookies from 'js-cookie';
import { store } from 'reducers';
import { logoutUser } from 'reducers/user';

export function encode(obj) {
  return Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&');
}

const API_URL = process.env.PREACT_APP_API_URL;

export const client = () => {
  const token = Cookies.get('metrical_token');
  const headers = Object.assign(
    {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    },
    token ? { Authorization: `Bearer ${token}` } : {},
  );
  const APIclient = axios.create({
    baseURL: API_URL,
    timeout: 10000,
    headers,
  });

  APIclient.interceptors.response.use(
    response => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch(logoutUser());
      }
      return error.response;
    },
  );

  return APIclient;
};


export default client;
