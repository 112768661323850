import React from 'react';
import cn from 'classnames';
import Icon from 'components/ui/Icon';
import { animated, useSpring } from 'react-spring';
import useKey from 'hooks/useKey';
import Animator from 'components/ui/Animator';
import { useAnimations } from 'hooks/useUser';
import { Link } from 'preact-router';
import css from './style.module.scss';

const ModalBox = ({
  children,
  className,
  withHeader = false,
  withShadow = false,
  closable = true,
  animate = false,
  size,
  onClose,
}) => {
  const close = () => {
    if (closable) {
      if (onClose) {
        onClose();
      } else {
        window.history.back();
      }
    }
  };
  useKey('esc', close);
  const animations = useAnimations() || animate;
  const openProps = useSpring({
    config: { duration: 200 },
    from: {},
    to: {},
  });
  return (
    <animated.div className={cn(css.modal)} style={openProps}>
      {closable && (
        <div className={css.closeButton} onClick={close}>
          <Icon name="Close" className={css.close} />
        </div>
      )}
      {withHeader && (
        <div className={css.modalBoxHeader}>
          <Link href="/">
            <img
              src={`${process.env.PREACT_APP_URL}/assets/metrical.logo.small.png`}
              alt="Metrical Logo"
              className={css.logoImage}
            />
          </Link>
        </div>
      )}
      <div
        className={cn(
          css.inner,
          withShadow && css.withShadow,
          css[size],
          className,
        )}
      >
        {animations ? <Animator>{children}</Animator> : children}
      </div>
    </animated.div>
  );
};

export default ModalBox;
