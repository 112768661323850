import React, { useEffect } from 'react';
import DetailsPage from 'components/apps/Details';
import Button from 'components/ui/Button';
import Panel from 'components/ui/Panel';
import { setUserDemo } from 'reducers/user';
import { store } from 'reducers';
import { Title } from 'components/ui/Typography';
import css from './style.module.scss';

export default ({ children, ...props }) => {
  useEffect(() => {
    store.dispatch(setUserDemo(true));
  }, []);
  return (
    <div className={css.demoPage}>
      <div closable={false} withShadow={false} title="👋 Hi There!" className={css.demoPanel}>
        <Title>👋 Hi There!</Title>
        <p>
        This is Metrical, a simple web analytics tool: All the data that you see are from the
        website
          {' '}
          <a href="https://metrical.xyz" rel="noopener noreferrer" target="_blank">
          metrical.xyz
          </a>
        .
        </p>
        <div className={css.buttons}>
          <Button to="/register" small className={css.cta}>
          Register now
          </Button>
          <Button link to="https://metrical.xyz" external>
          Learn More
          </Button>
        </div>
      </div>
      <DetailsPage appId={process.env.PREACT_APP_DEMO_APP} demo {...props} />

      {/* <div className={css.demoPanel}>
        <div className={css.container}>
          <h2>👋 Hi There!</h2>

        </div>
      </div> */}
      {children}
    </div>

  );
};
