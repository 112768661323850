import React from 'react';
import { Formik, Field } from 'formik';
import { Input } from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import { formatWebsite } from 'shared/utils';
import { useDispatch } from 'react-redux';
import { toast } from 'reducers/toasts';
import { setApp } from 'reducers/apps';
import { SectionTitle } from 'components/ui/Typography';
import { saveApp } from './api';
import css from './style.module.scss';

const EditForm = ({ app }) => {
  const dispatch = useDispatch();
  const onSubmit = (values, actions) => {
    actions.setSubmitting(false);
    const website = formatWebsite(values.website);
    saveApp(app.uuid, { name: values.name, website }).then((response) => {
      dispatch(setApp(response));
      dispatch(toast({ message: 'App saved.' }));
    });
  };
  return (
    <div>
      <SectionTitle className={css.title}>General Settings</SectionTitle>

      <Formik
        initialValues={{ ...app }}
        onSubmit={onSubmit}
        render={({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className={css.form}>
            <Field
              autofocus
              placeholder="Dundler Miffin Paper Company, Inc"
              component={Input}
              name="name"
              type="text"
              label="Application Name"
            />
            <Field
              placeholder="dundermifflinpaper.com"
              component={Input}
              name="website"
              type="text"
              label="Website URL"
            />
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </form>
        )}
      />
    </div>
  );
};

export default EditForm;
