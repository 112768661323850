/* eslint-disable no-param-reassign */
import React from 'react';
import SectionBarVisualizer from '../SectionBarVisualizer';

const normalizeData = (data, addFilter, params) => data.referrals.reduce(
  (acc, el) => {
    let value = 'Direct';
    // android referrers custom
    if (el.custom) {
      value = `${el.name} 📱`;
    } else if (el.ref) {
      value = params.referrer
        ? el.ref.replace(params.referrer.value, '')
        : el.ref;
      value = value.replace(/http(s)?:\/\/(www.)?/, '').replace('www.', '');
      value = value.length > 0 ? value : '/';
      el.url = `https://${el.ref}`;
    }

    acc.maxValue = acc.maxValue > parseInt(el.count, 10) ? acc.maxValue : parseInt(el.count, 10);
    acc.items.push({
      value,
      link: value === 'Direct' ? null : el.url,
      count: el.count,
      onClick: () => {
        if (el.ref) {
          addFilter('referrer', el.ref, { value: el.ref, name: 'Source' });
        }
      },
    });
    return acc;
  },
  { items: [], max: 1, columns: ['source', 'visits'] },
);
export const SourceBarChart = ({
  current,
  params,
  color,
  app,
  addFilter,
  header,
  realTime,
  showLoader = true,
}) => (
  <SectionBarVisualizer
    withHeader={!!header}
    header={header}
    url={`/apps/${app.uuid}/visits/sources/${current}`}
    params={params}
    normalizer={normalizeData}
    showPages
    showFavicons
    animations={!realTime}
    name="referrer"
    addFilter={addFilter}
    barColor={color}
    showLoader={showLoader}
  />
);

export default SourceBarChart;
