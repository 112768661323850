import { createAction, createReducer } from 'redux-act';
import { normalize, schema } from 'normalizr';

export const saveApps = createAction('saveApps');
export const setApp = createAction('setApp');
export const setApps = createAction('setApps');

const defaultState = {};

const app = new schema.Entity(
  'apps',
  {},
  {
    idAttribute: 'uuid',
  },
);

export default createReducer(
  {
    [setApps]: (state, payload) => {
      const norm = normalize(payload, [app]);
      return norm.entities.apps || {};
    },
    [setApp]: (state, payload) => {
      const norm = normalize(payload, app);
      return { ...state, ...norm.entities.apps };
    },
    [saveApps]: (state, payload) => {
      const norm = normalize(payload, [app]);
      return { ...state, ...norm.entities.apps };
    },
  },
  defaultState,
);
