import React, { useEffect } from 'react';
import useFetch from 'hooks/useFetch';

import Redirect from 'components/ui/Redirect';
import {
  subDays,
  startOfDay,
  endOfDay,
  differenceInCalendarDays,
} from 'date-fns';
import Flex from 'components/ui/Flex';
import Favicon from 'components/ui/Favicon';
import cn from 'classnames';
import Loading from 'components/ui/Loading';
import { setApp } from 'reducers/apps';
import getApp from 'hooks/useApp';
import { getPreviousPeriod } from 'shared/utils';
import {
  addFilter,
  removeFilter,
  addRawFilters,
  setupFilters,
} from 'reducers/app';
import Tabs from 'components/ui/Tabs';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import { Small, TabletOrDesktop } from 'components/ui/MediaQuery';
import GeneralSection from './General';
import RealTimeSection from './RealTime';
import SettingsSection from './Settings';
import css from './style.module.scss';
import DatePicker from './DatePicker';
import AppInfo from './AppInfo';
import ExtraFilters from './General/sections/ExtraFilters';

const BackButton = ({ to }) => (
  <Button link to={to} className={css.backButton}>
    <Icon name="Back" />
  </Button>
);

const MobileHeader = ({ app, demo }) => {
  if (demo) {
    return (
      <div className={cn(css.mobileHeader, css.mobileHeaderDemo)}>
        <Button link external to="https://metrical.xyz">
          What is Metrical?
        </Button>
        <Button to="/register" className={css.signupButton}>
          Sign up now
        </Button>
      </div>
    );
  }

  return (
    <div className={css.mobileHeader}>
      <BackButton to="/" />
      <Favicon
        url={app.favicon}
        miniImage={app.userRole !== 'owner' && app.owner.avatar}
        size="normal"
        className={css.mobileFavicon}
      />
    </div>
  );
};

const createTabs = (prefix, demo, app) => {
  const output = [
    { id: '', name: 'General', link: prefix },
    { id: 'current', name: 'Real Time', link: `${prefix}/current` },
  ];
  if (!demo && app.userRole === 'owner') {
    output.push({
      id: 'settings',
      name: 'Settings',
      link: `${prefix}/settings`,
    });
  }
  return output;
};

export default ({
  appId, children, demo, tab = '',
}) => {
  const from = startOfDay(subDays(new Date(), 14));
  const to = endOfDay(new Date());

  const defaultParams = {
    from,
    to,
    group: { value: 'pageviews' },
    timeSection: 'day',
    previous: getPreviousPeriod({ from, to }),
  };

  const prefix = demo ? '/demo' : `/a/${appId}`;
  const app = getApp(appId);
  const dispatch = useDispatch();
  const { filters } = useSelector(state => state.app);
  useEffect(() => {
    dispatch(setupFilters(defaultParams));
  }, []);
  const { loading, error } = useFetch(`/apps/${appId}`, {
    dispatchAction: setApp,
  });

  const addFilterAction = (name, value, extra) => {
    dispatch(addFilter({ name, value, extra }));
  };
  const changeGroup = (value) => {
    dispatch(addFilter({ name: 'group', value }));
  };
  const removeFilterAction = (name) => {
    dispatch(removeFilter(name));
  };

  if (error) {
    return <div>Error</div>;
  }
  if (!app || loading) {
    return <Loading fullpage />;
  }
  const handleDaySelect = ({ from, to, label }) => {
    dispatch(
      addRawFilters({
        from,
        to,
        label,
        previous: getPreviousPeriod({ from, to }),
        timeSection: differenceInCalendarDays(to, from) < 3 ? 'hour' : 'day',
      }),
    );
  };
  const tabs = createTabs(prefix, demo, app);
  if (!tabs.map(t => t.id).includes(tab)) {
    return <Redirect to={prefix} noThrow />;
  }
  return (
    <div className={css.container}>
      <MobileHeader app={app} demo={demo} />
      <Flex alignCenter className={css.header}>
        <Favicon
          url={app.favicon}
          miniImage={app.userRole !== 'owner' && app.owner.avatar}
          size="normal"
          className={css.favicon}
        />
        <AppInfo {...app} demo={demo} />

        <TabletOrDesktop>
          <DatePicker {...filters} desktop onChangeDate={handleDaySelect} />
        </TabletOrDesktop>
      </Flex>

      <Tabs className={css.appMobileTabs} selected={tab} tabs={tabs} />
      {tab === '' && (
        <>
          <Flex className={css.filterContainer}>
            <Small>
              <DatePicker {...filters} mobile onChangeDate={handleDaySelect} />
            </Small>
            <ExtraFilters
              params={filters}
              changeGroup={changeGroup}
              removeFilter={removeFilterAction}
            />
          </Flex>
          <GeneralSection
            app={app}
            onChangeDate={handleDaySelect}
            params={filters}
            addFilter={addFilterAction}
            removeFilter={removeFilterAction}
          />
        </>
      )}
      {tab === 'current' && <RealTimeSection app={app} params={filters} />}
      {!demo && tab === 'settings' && <SettingsSection app={app} />}
      {children}
    </div>
  );
};
