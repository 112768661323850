import { useSelector } from 'react-redux';

export default () => useSelector(state => state.ui.theme);

export const COLORS = {
  light: {
    primaryColor10: '#cff3ef',
    primaryColor100: '#42d9c8',
    secondaryColor10: '#8fc7ce',
    secondaryColor100: '#2c8c99',
    accentColor10: '#e49fa5',
    accentColor100: '#e17055',
    textColor: '#afbcbe',
    whiteColor: '#ffffff',
    primaryColor: '#666',
    warnColor10: '#ffd8b5',
    warnColor100: '#ffb16c',
    backgroundColor: '#fff',
    backgroundColorLight: '#fafafa',
    borderColor: '#f5f5f5',
    ternaryColor100: '#2e86de',
    purple100: '#6C4682',
    purple10: '#B6A2C0',
    lightBlue100: '#5BB9CE',
    lightBlue10: '#ADDCE7',
    orange100: '#CD7840',
    orange10: '#E6BB9F',
    green100: '#59C0A6',
    green10: '#7ACDB8',
    red10: '#ff7675',
    red100: '#d63031',
  },
  dark: {
    primaryColor10: '#2e3937',
    primaryColor100: '#267b71',
    secondaryColor10: '#74a2a8',
    secondaryColor100: '#2c8c99',
    accentColor10: '#A3434C',
    accentColor100: '#931621',
    textColor: '#afbcbe',
    whiteColor: '#ffffff',
    primaryColor: '#666',
    warnColor10: '#9F744E',
    warnColor100: '#ffb16c',
    backgroundColor: '#161625',
    backgroundColorLight: '#161616',
    borderColor: '#434848',
    ternaryColor100: '#2e86de',
    purple100: '#48255c',
    purple10: '#644b71',
    lightBlue100: 'rgb(57, 115, 128)',
    lightBlue10: 'rgb(87, 132, 142)',
    orange100: '#CD7840',
    orange10: '#E6BB9F',
    green100: 'rgb(39, 84, 73)',
    green10: 'rgb(76, 125, 113)',
    red10: '#ff7675',
    red100: '#d63031',
  },
};

export const useColors = () => {
  const theme = useSelector(state => state.ui.theme);
  return COLORS[theme];
};

export const useColor = (color) => {
  const theme = useSelector(state => state.ui.theme);
  return COLORS[theme][color];
};
