import React, { useState } from 'react';
import Flex from 'components/ui/Flex';
import Box from 'components/ui/Box';
import useInterval from 'hooks/useInterval';
import PagesSection from 'components/apps/Details/General/sections/Pages';
import ReferralsSection from 'components/apps/Details/General/sections/Sources/SourceBarChart';
import { useColors } from 'hooks/useTheme';
import { subMinutes } from 'date-fns';
import { usePageVisibility } from 'react-page-visibility';
import InfoSection from './sections/Info';
import VisitsSection from './sections/Visits';
import css from './style.module.scss';
import SectionHeader from '../SectionHeader';

const Loader = () => {
  const colors = useColors();

  return (
    <div className="realtime-loader">
      <div className="wrapper">
        <div
          style={{ backgroundColor: colors.backgroundColorLight }}
          className="leftHalf"
        />
        <div
          style={{ backgroundColor: colors.secondaryColor10 }}
          className="spinner"
        />
        <div
          style={{ backgroundColor: colors.secondaryColor10 }}
          className="rightHalf"
        />
        <div
          className="up"
          style={{ backgroundColor: colors.backgroundColorLight }}
        />
      </div>
    </div>
  );
};

export const RealTime = ({ app }) => {
  const colors = useColors();
  const isVisible = usePageVisibility();
  const [reloadDate, setReloadDate] = useState(new Date().getTime());
  useInterval(() => {
    if (isVisible) {
      setReloadDate(new Date().getTime());
    }
  }, 10000);

  const from = subMinutes(new Date(), 30);
  const to = new Date();
  const params = {
    from,
    to,
    group: { value: '' },
    timeSection: 'minute',
    reload: reloadDate,
  };

  return (
    <>
      <Flex alignCenter className={css.realtimeInfo}>
        <Loader time={10000} />
        <h3 className={css.realtimeInfoH3}>
          All data in this page are refreshed every 10 seconds.
        </h3>
      </Flex>
      <Flex tabletResponsive className={css.sectionContainer}>
        <Box size="2_3" first noBorder>
          <SectionHeader
            title="Real Time"
            subtitle="All visits of the last 30 minutes"
          />
          <VisitsSection app={app} params={params} />
        </Box>
        <InfoSection
          realtime
          color={colors.primaryColor10}
          app={app}
          params={params}
          title="Top pages"
          subtitle="Over the last 30 minutes"
        />
      </Flex>
      <Flex tabletResponsive className={css.sectionContainer}>
        <Box>
          <PagesSection
            color={colors.lightBlue100}
            app={app}
            realTime
            params={params}
            title="Top best pages"
            subtitle="What pages have been visited the most in the last 30 minutes?"
          />
        </Box>
        <Box>
          <ReferralsSection
            params={params}
            app={app}
            realTime
            showLoader={false}
            color={colors.secondaryColor10}
            current="referrals"
            header={{
              title: 'Top referrals',
              subtitle:
                'What sources brought traffic to your site in the last 30 minutes?',
            }}
          />
        </Box>
      </Flex>
    </>
  );
};

export default RealTime;
