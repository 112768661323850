import React, { useState } from 'react';
import useFetch from 'hooks/useFetch';
import { normalizeChartData } from 'shared/visits';
import {
  prepareParams,
} from 'components/apps/Details/DataLoader';
import css from './style.module.scss';
import VisitsChart from './Chart';


const dictionary = {
  sessions: 'Sessions',
  pageviews: 'Pageviews',
};

const prepareSerie = (name, { elements, days }) => ({
  name,
  data: elements,
  categories: days,
});

export const Visits = ({ app, params, wideChart }) => {
  const { response: data } = useFetch(
    `/apps/${app.uuid}/visits`,
    {
      params: prepareParams(params),
      defaultResponse: { empty: true },
      map: response => normalizeChartData(response.visits, params),
    },
  );
  // if (loading) {
  //   return (
  //     <div className={css.visits}>
  //       <Loading height="300px" />
  //     </div>
  //   );
  // }
  return (
    <div className={css.visits}>
      {/* {loading ? <Loading/> } */}
      {!data.empty && (
        <VisitsChart
          type="bar"
          xaxis={data.days}
          data={[prepareSerie('Last 30 minutes', data)]}
          wideChart={wideChart}
          title={dictionary[params.group.value]}
          params={params}
        />
      )}
      {data.empty && (
        <div className={css.noData}>
          <img src="https://media.giphy.com/media/g01ZnwAUvutuK8GIQn/giphy-downsized.gif" />
          Seems like nobody is visiting
          {' '}
          {app.name}
          {' '}
right now...
        </div>
      )}
      {/* </Displayer> */}
    </div>
  );
};

export default Visits;
