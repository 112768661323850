import { useCallback, useEffect } from 'react';

const rewrite = {
  esc: 'Escape',
};

export default (key, callback) => {
  const meimoisedCallback = useCallback(callback, []);
  const keyToWatch = rewrite[key] || key;
  const match = event => (event.key ? keyToWatch.toLowerCase() === event.key.toLowerCase() : false);
  const onDown = (event) => {
    if (match(event)) {
      meimoisedCallback();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', onDown);
    return () => {
      window.removeEventListener('keydown', onDown);
    };
  }, [key]);
};
