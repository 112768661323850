import React from 'react';
import useFetch from 'hooks/useFetch';
import { Title, SectionTitle, Text } from 'components/ui/Typography';
import { useSelector } from 'react-redux';
import { setApps } from 'reducers/apps';
import useUser from 'hooks/useUser';
import Button from 'components/ui/Button';
import Loading from 'components/ui/Loading';
import Confetti from 'react-confetti';
import { Router } from 'preact-router';
import AppCreate from 'components/apps/Create';
import { TrialBox, TrialEnded } from 'components/Subscribe/Trial';
import AppItem from './AppItem';
import css from './style.module.scss';

const getApps = apps => Object.keys(apps).map(k => apps[k]);
const UpgradeBox = () => (
  <div className={css.upgradeBox}>
    <SectionTitle>Want to keep track of your websites?</SectionTitle>
    <Text>Upgrade now and add your own websites to Metrical</Text>
    <Button to="/subscribe">Upgrade now</Button>
  </div>
);


const NewUserBox = () => (
  <div className={css.newUserBox}>
    <Confetti
      colors={['#2c8c99', '#8fc7ce', '#cff3ef', '#42d9c8']}
      numberOfPieces={100}
      opacity={0.8}
      recycle={false}
      width={document.body.clientWidth}
      height={document.body.clientHeight}
    />
    <Title>Welcome to Metrical! ✨</Title>
    <Text>
      We are really excited to have you here!
      <br />
      You can try Metrical for the next 2 weeks with
      {' '}
      <b>10.000 visits</b>
/month
      {' '}
between
      {' '}
      <b>2 websites</b>
      {' '}
.

      <br />
      <br />
      Now, are you ready to add your first website?🚀
    </Text>
    <Button to="/new">Add your first website</Button>
    <br />
    <br />
    <br />
    {' '}
    <br />
    <Text>
      If you need any help, remember that you can always
      {' '}
      <Button external link to="https://t.me/sono_franco">
        contact us on Telegram
      </Button>
      !
    </Text>
  </div>
);

export default ({ children }) => {
  const user = useUser();
  const apps = getApps(useSelector(state => state.apps));
  const { loading, error } = useFetch('/apps', {
    dispatchAction: setApps,
  });
  if (error) {
    return <div>Error</div>;
  }

  return (
    <div>
      <div className={css.homepage}>
        {!loading && apps.length === 0 && <NewUserBox />}
        {apps.length > 0 && (
          <>
            <div className={css.header}>
              <Title>Your websites</Title>

              {user.subscription.status === 0
              && apps.filter(a => a.userRole === 'owner').length > 1 ? (
                <Button
                  className={css.add}
                  link
                  to="/subscribe"
                  params={{
                    reason: 'apps_exceeded',
                  }}
                >
                  + Add a new website
                </Button>
                ) : (
                  <Button className={css.add} link to="/new">
                  + Add a new website
                  </Button>
                )}
            </div>


            <div className={css.content}>
              {user.subscription.isTrial && (
              <TrialBox user={user} subscription={user.subscription} />
              )}
              {user.subscription.plan !== 'beta' && user.subscription.status === 0 && user.subscription.trialEnded && (
              <TrialEnded subscription={user.subscription} user={user} />
              )}
              {loading && <Loading fullPage />}
              {!loading && (
                <>
                  <div className={css.appList}>
                    {apps.map(app => (
                      <AppItem
                        animate={user.animations}
                        key={app.id}
                        {...app}
                      />
                    ))}
                    {/* {user.subscription.status === 0 && <UpgradeBox />} */}
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <Router>
        <AppCreate path="/new" />
      </Router>
      {children}
    </div>
  );
};
