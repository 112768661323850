const plans = [
  {
    id:
      process.env.PREACT_APP_ENV !== 'production'
        ? 'price_1H2KjzFG6F9RaR3DjBlyBJKx'
        : 'price_1H985fFG6F9RaR3DS3CPD212',
    price: '50$',
    afterPrice: ' / Year',
    description: '✨4+ months free: 4.10$ / Month ',
    title: 'Every Year',
    name: '1 Year Plan',
  },
  {
    id:
      process.env.PREACT_APP_ENV !== 'production'
        ? 'price_1H2KhDFG6F9RaR3DxS1vRI03'
        : 'price_1H985fFG6F9RaR3DRthFf954',
    price: '7$',
    afterPrice: ' / Month',
    description: '',
    title: 'Every Month',
    name: '1 Month Plan',
  },

];

export default plans;
