import React from 'react';
import { animated } from 'react-spring';
import Icon from 'components/ui/Icon';
import Button from 'components/ui/Button';
import cn from 'classnames';
import numeral from 'numeral-es6';
import css from './style.module.scss';

const FaviconImage = ({ link }) => {
  try {
    const { hostname } = new URL(link);
    const faviconUrl = `https://icons.duckduckgo.com/ip3/${hostname}.ico`;
    return <img src={faviconUrl} referrerpolicy="no-referrer" style={{ marginRight: 8, width: 15, height: 15 }} alt={`favicon of ${hostname}`} />;
  } catch (error) {
    return <img src="https://dummyimage.com/15x15/000/fff&text=+" referrerpolicy="no-referrer" style={{ marginRight: 8, width: 15, height: 15 }} alt={`No favicon available for ${link}`} />;
  }
};

const SectionBarRow = ({
  value,
  count,
  index,
  percentage,
  onClick,
  barColor,
  style,
  showFavicons = false,
  link = false,
}) => (
  <div className={css.row} style={style}>
    <div className={css.text}>
      <div className={css.label} onClick={onClick}>
        <span className={css.index}>{index + 1}</span>
        <div className={cn(css.text, onClick && css.clickable)}>
          {link && showFavicons && !link.startsWith('@') && <FaviconImage link={link} /> }
          {value}
        </div>
      </div>
      <div className={css.barContainer}>
        <animated.div
          className={css.bar}
          style={{ background: barColor, width: percentage }}
        />
      </div>
    </div>
    <div className={css.right}>
      {' '}
      {link && (
      <Button link external to={link} className={css.button}>
        <Icon name="Launch" />
      </Button>
      )}
      <div className={css.count}>{numeral(count).format('0,0a')}</div>
    </div>
  </div>
);
export default animated(SectionBarRow);
