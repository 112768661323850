import React from 'react';
import useFetch from 'hooks/useFetch';
import Loading from 'components/ui/Loading';
import { SectionTitle, Text } from 'components/ui/Typography';
import Button from 'components/ui/Button';
import useUser from 'hooks/useUser';
import { TrialBox, BetaUser } from 'components/Subscribe/Trial';
import css from './style.module.scss';

const Stripe = () => {
  const user = useUser();

  const { response, loading } = useFetch(user.subscription.status !== 0 && '/subscriptions/sessions/edit');

  if (user.subscription.plan === 'free') {
    return <TrialBox subscription={user.subscription} />;
  }
  if (user.subscription.plan === 'beta') {
    return <BetaUser subscription={user.subscription} />;
  }
  if (user.subscription.status === 0) {
    return null;
  }
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <SectionTitle className={css.title}>
        Manage your subscription
      </SectionTitle>
      <div className={css.invoices}>
        <Text>
          Metrical use
          {' '}
          <b>Stripe</b>
          {' '}
to manage your subscription.
          {' '}
          <br />
          Click on the button below if you want to edit, cancel or upgrade your
          subscription.
        </Text>
        <Button className={css.editSubscription} to={response.url}>
          Edit Subscription
        </Button>
      </div>
    </div>
  );
};

export default Stripe;
