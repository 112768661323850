import React from 'react';
import cn from 'classnames';
import css from './style.module.scss';

export const Title = ({ children, className, ...rest }) => (
  <h1 {...rest} className={cn(css.title, className)}>
    {children}
  </h1>
);

export const SubTitle = ({ children, className, ...rest }) => (
  <h3 {...rest} className={cn(css.subTitle, className)}>
    {children}
  </h3>
);

export const SectionTitle = ({ children, className, ...rest }) => (
  <h3 {...rest} className={cn(css.sectionTitle, className)}>
    {children}
  </h3>
);

export const SectionSubTitle = ({ children, className, ...rest }) => (
  <h5 {...rest} className={cn(css.sectionSubTitle, className)}>
    {children}
  </h5>
);

export const Text = ({ children, className, ...rest }) => (
  <p {...rest} className={cn(css.text, className)}>
    {children}
  </p>
);

export const SmallText = ({ children, className, ...rest }) => (
  <p {...rest} className={cn(css.smallText, className)}>
    {children}
  </p>
);

export default {};
