import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import css from './style.scss';

const Animator = ({
  children, delay = 100, className, type = 'fade-up',
}) => {
  const [klass, setKlass] = useState();
  const baseClass = css[`base-${type}`];
  useEffect(() => {
    const timer = setTimeout(() => {
      setKlass(css[`animate-${type}`]);
    }, delay);
    return () => clearTimeout(timer);
  }, []);
  return <div className={cn(baseClass, klass, className)}>{children}</div>;
};

export default Animator;
