import React from 'react';
import { Input } from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import { Formik, Field } from 'formik';
import { route } from 'preact-router';

import { Title } from 'components/ui/Typography';
import ModalBox from 'components/ui/ModalBox';
import css from './style.module.scss';
import api from './api';

const SignInForm = () => {
  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    const [response] = await api.signIn(values);
    if (response) {
      route('/');
    }
  };
  return (
    <ModalBox withHeader animate closable={false}>
      <Title>Login</Title>
      <div className={css.form}>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={onSubmit}
          render={({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                component={Input}
                name="email"
                type="email"
                label="Your Email"
              />
              <Field
                component={Input}
                name="password"
                type="password"
                label="Your Password"
              />

              <Button type="submit" disabled={isSubmitting}>
                Sign In
              </Button>
              <div className={css.links}>
                <Button link secondary to="/register">
                  Register now
                </Button>
                <Button link secondary to="/password/forgot">
                  Forgot password?
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </ModalBox>
  );
};

export default SignInForm;
