import React from 'react';
import cn from 'classnames';
import css from './style.module.scss';

export const Flex = ({
  children,
  center,
  vertical,
  alignStart,
  alignEnd,
  alignCenter,
  justBetween,
  justEnd,
  justCenter,
  className,
  tabletResponsive,
  style,
}) => {
  const classes = cn(
    css.flex,
    vertical && css.flex_vertical,
    center && css.flex_center,
    alignStart && css.flex_align_start,
    alignEnd && css.flex_align_end,
    alignCenter && css.flex_align_center,
    justBetween && css.flex_just_between,
    justEnd && css.flex_just_end,
    justCenter && css.flex_just_center,
    tabletResponsive && css.columnStartsAtTablet,
    className,
  );
  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export default Flex;
