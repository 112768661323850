import React from 'react';
import useFetch from 'hooks/useFetch';
import Loading from 'components/ui/Loading';
import {
  Title,
  SectionTitle,
  Text,
} from 'components/ui/Typography';
import numeral from 'numeral-es6';
import { useColors } from 'hooks/useTheme';
import css from './style.module.scss';

const getColors = (percentage, colors) => {
  if (percentage < 70) {
    return [colors.secondaryColor10, colors.secondaryColor100];
  }
  if (percentage > 95) {
    return [colors.accentColor10, colors.accentColor100];
  }
  return [colors.warnColor10, colors.warnColor100];
};

const Visits = () => {
  const c = useColors();

  const { response, loading } = useFetch('/users/me/visits');
  if (loading) {
    return <Loading />;
  }
  const colors = getColors(response.percentage, c);
  return (
    <div className={css.visits}>
      <SectionTitle>Total Visits</SectionTitle>
      <Title className={css.value}>
        {numeral(response.visits).format('0,0')}
        {' '}
        <span>visits this month</span>
      </Title>
      <div className={css.barContainer}>
        <div
          className={css.bar}
          style={{
            backgroundColor: colors[0],
            width: `${Math.min(response.percentage, 100)}%`,
          }}
        />
        <div className={css.perc}>
          {numeral(response.percentage).format('0,0.00')}
% of
          {' '}
          {numeral(response.max).format('0,0')}
        </div>
      </div>
      <Text className={css.info}>
        We'll notify you when you exceed
        {' '}
        {numeral(response.max).format('0,0')}
        {' '}
        visits for 2 months in a row.
      </Text>
    </div>
  );
};

export default Visits;
