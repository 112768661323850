import { createAction, createReducer } from 'redux-act';
import Cookies from 'js-cookie';

export const setUser = createAction('setUser');
export const updateUser = createAction('updateUser');
export const setUserDemo = createAction('setUserDemo');
export const logoutUser = createAction('logoutUser');

const defaultState = { authenticated: false, animations: true, demo: false };

export default createReducer(
  {
    [setUser]: (state, payload) => {
      Cookies.set('metrical_token', payload.token);
      return { ...payload, authenticated: true };
    },
    [setUserDemo]: (state, payload) => ({ ...state, demo: payload }),
    [updateUser]: (state, payload) => ({ ...state, ...payload, authenticated: true }),
    [logoutUser]: () => {
      Cookies.remove('metrical_token');
      return defaultState;
    },
  },
  defaultState,
);
