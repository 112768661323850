import React from 'react';
import cn from 'classnames';
import {
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfDay,
  subDays,
  endOfDay,
  format,
  subYears,
} from 'date-fns';
import css from './style.module.scss';

const ShortCut = ({
  name, label, from, to, onSelect, selected,
}) => (
  <div
    className={cn(css.shortCut, selected && css.shortCutSelected)}
    onClick={e => e.preventDefault() || e.stopPropagation() || onSelect({ from, to, label })}
  >
    <span className={css.label}>{name}</span>
    <span className={css.dates}>
      {format(from, 'YYYY-MM-DD')}
      {' '}
-
      {' '}
      {format(to, 'YYYY-MM-DD')}
    </span>
  </div>
);
const ShortcutsList = ({ onDateChange, currentShortcut }) => {
  const onSelect = (shortCut) => {
    onDateChange({
      from: shortCut.from,
      to: shortCut.to,
      label: shortCut.label,
    });
  };
  const shortCuts = [
    {
      name: 'Today',
      label: 'today',
      from: startOfDay(new Date()),
      to: endOfDay(new Date()),
    },
    {
      name: 'Last 7 Days',
      label: 'last_7_days',
      from: subDays(startOfDay(new Date()), 7),
      to: endOfDay(new Date()),
    },
    {
      name: 'Last 14 Days',
      label: 'last_14_days',
      from: subDays(startOfDay(new Date()), 14),
      to: endOfDay(new Date()),
    },
    {
      name: 'Last 30 days',
      label: 'last_30_days',
      from: subDays(startOfDay(new Date()), 30),
      to: endOfDay(new Date()),
    },
    {
      name: 'Last month',
      label: 'last_month',
      from: startOfMonth(subMonths(new Date(), 1)),
      to: endOfMonth(subMonths(new Date(), 1)),
    },
    {
      name: 'Last 3 months',
      label: 'last_3_months',
      from: startOfMonth(subMonths(new Date(), 3)),
      to: endOfDay(new Date()),
    },
    {
      name: 'Last 12 months',
      label: 'last_12_months',
      from: subYears(new Date(), 1),
      to: endOfDay(new Date()),
    },
  ];
  return (
    <div className={css.shortcutList}>
      {shortCuts.map(s => (
        <ShortCut
          key={s.label}
          {...s}
          onSelect={onSelect}
          selected={s.label === currentShortcut}
        />
      ))}
    </div>
  );
};

export default ShortcutsList;
