import React, { useRef } from 'react';
import { useColors } from 'hooks/useTheme';
import cn from 'classnames';
import numeral from 'numeral-es6';
import css from './style.module.scss';

const Label = ({
  name, onClick, selected, color, id, perc,
}) => (
  <button
    type="button"
    className={cn(css.label, selected && css.selected)}
    onClick={() => onClick(id)}
  >
    <div className={css.name}>{name}</div>
    <div className={css.color} style={{ backgroundColor: color }} />

    <div className={css.number}>
      {numeral(perc).format('0,0')}
%
    </div>
  </button>
);

const BarChart = ({ items, selected, onClick }) => {
  const ref = useRef(null);
  const colors = useColors();

  const total = items.reduce((_total, i) => _total + i.value, 0);

  const series = items.map(i => ({ ...i, perc: (i.value * 100) / total }));

  return (
    <div ref={ref} className={css.pieContainer}>
      <div className={css.pie}>
        <div className={css.barContainer}>
          {series.map((s, i) => (
            <button
              type="button"
              onClick={() => onClick(s.id)}
              className={css.sourceBar}
              key={s.name}
              style={{
                minWidth: i === series.length - 1 ? `${s.perc}%` : `calc(${s.perc}% - 4px)`,
                borderColor: `${colors[`${s.color}0`]}`,
                backgroundColor: colors[s.color],
              }}
            />
          ))}
        </div>
      </div>
      <div className={css.labels}>
        {series.map(s => (
          <Label
            perc={s.perc}
            name={s.name}
            value={s.value}
            key={s.id}
            id={s.id}
            onClick={onClick}
            selected={selected === s.id}
            color={colors[s.color]}
          />
        ))}
      </div>
    </div>
  );
};

export default BarChart;
