import React from 'react';
import {
  SectionTitle, Text,
} from 'components/ui/Typography';

import { Formik, Field } from 'formik';
import { Input } from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import { useDispatch } from 'react-redux';
import { toast } from 'reducers/toasts';
import { setApp } from 'reducers/apps';
import Icon from 'components/ui/Icon';
import css from './style.module.scss';
import { inviteCollaborator, removeCollaborator } from './api';

const Collaborator = ({
  role, status, email, id, app,
}) => {
  const dispatch = useDispatch();
  const onDelete = async () => {
    const response = await removeCollaborator(app.uuid, email);
    dispatch(toast({ message: 'Collaborator removed' }));
    dispatch(setApp(response));
  };
  return (
    <div className={css.collaborator}>
      <div className={css.close} onClick={onDelete}>
        <Icon name="Close" />
      </div>
      {email}
      {' '}
(
      {status}
)
    </div>
  );
};

export default ({ app }) => {
  const dispatch = useDispatch();
  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    const response = await inviteCollaborator(app.uuid, values.email);
    let message = '';
    if (response.status === 'already_in') {
      message = `This collaborator has already access to ${app.name}.`;
    }
    if (response.status === 'invited') {
      message = 'Collaborator invited';
    }
    if (response.status === 'added') {
      message = 'Collaborator added';
    }
    dispatch(toast({ message }));
    dispatch(setApp(response.app));
  };
  return (
    <div className={css.collaborators}>
      <SectionTitle className={css.title}>Collaborators</SectionTitle>
      <Text>
        Add collaborators and let them access Metrical to view your wonderful
        data.
      </Text>
      <div className={css.collaboratorList}>
        {app.collaborators.map(c => (
          <Collaborator app={app} key={c.email} {...c} />
        ))}
      </div>
      <Formik
        onSubmit={onSubmit}
        initialValues={{ email: '' }}
        render={({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className={css.form}>
            <Field
              placeholder="michael.scott@gmail.com"
              component={Input}
              name="email"
              type="email"
              label="Invite new collaborator"
            />
            <Button type="submit" track="settings_add_collaborator" disabled={isSubmitting}>
              Invite
            </Button>
          </form>
        )}
      />
    </div>
  );
};
