import React, { useState } from 'react';
import SectionHeader from 'components/apps/Details/SectionHeader';
import Tabs from 'components/ui/Tabs';
import { SectionTitle, SubTitle } from 'components/ui/Typography';
import { capitalize } from 'shared/utils';
import { Link } from 'preact-router';
import Button from 'components/ui/Button';
import css from './style.module.scss';
import SectionBarVisualizer from '../SectionBarVisualizer';

const UTM_CONFIG = {
  sources: {
    filterID: 'utmSource',
    filterName: 'UTM Source',
    columnName: 'source',
  },
  campaigns: {
    filterID: 'utmCampaign',
    filterName: 'UTM Campaign',
    columnName: 'campaign',
  },
  medium: {
    filterID: 'utmMedium',
    filterName: 'UTM Medium',
    columnName: 'medium',
  },

};


const normalizeData = utmType => (data, addFilter) => data[utmType].reduce(
  (acc, el) => {
    acc.maxValue = acc.maxValue > parseInt(el.count) ? acc.maxValue : parseInt(el.count);
    acc.items.push({
      value: el.name || '-',
      count: el.count,
      onClick: () => {
        addFilter(UTM_CONFIG[utmType].filterID, el.name, {
          value: el.name,
          name: UTM_CONFIG[utmType].filterName,
        });
      },
    });
    return acc;
  },
  { items: [], max: 1, columns: [UTM_CONFIG[utmType].columnName, 'visits'] },
);

export const UTM = ({
  app, params, addFilter, colors,
}) => {
  const [currentUTM, setCurrentUTM] = useState('sources');

  return (
    <div className={css.referrals}>
      <SectionHeader
        title="UTM codes"
        down
        download={false}
        subtitle="Use UTM codes to track the performance of your social campaigns."
      />
      <Tabs
        tabs={[
          {
            name: 'Sources',
            id: 'sources',
          },
          {
            name: 'Campaigns',
            id: 'campaigns',
          },
          {
            name: 'Medium',
            id: 'medium',
          },
        ]}
        onSelect={id => setCurrentUTM(id)}
        selected={currentUTM}
      />
      <SectionBarVisualizer
        withHeader={false}
        downloadFileName={`${app.name} - UTM`}
        url={`/apps/${app.uuid}/visits/utm/${currentUTM}`}
        params={params}
        normalizer={normalizeData(currentUTM)}
        showPages
        name={`UTM ${currentUTM}`}
        addFilter={addFilter}
        barColor={colors[currentUTM]}
      />
      <div style={{ fontSize: '0.9em', lineHeight: '1.5em' }}>
Add
        {' '}
        <code style={{
          background: '#f5f5f5',
          border: '1px solid #eee',
          borderRadius: 3,
          padding: 3,
        }}
        >
          {`?utm_${UTM_CONFIG[currentUTM].columnName}=VALUE`}

        </code>
        {' '}
to links to your website to view them here.
        {' '}
        <br />
        <Button link external to="https://buffer.com/library/utm-guide/">Learn more about UTM</Button>
      </div>
    </div>
  );
};

export default UTM;
