import React from 'react';
import Icon from 'components/ui/Icon';
import css from './style.module.scss';

const Filter = ({
  name, value, removeFilter, id,
}) => (
  <div className={css.filterContainer}>
    <div className={css.filter}>
      <div className={css.name}>{name}</div>
      <div className={css.value}>{value}</div>
    </div>
    <button
      className={css.removeFilter}
      type="button"
      onClick={() => removeFilter(id)}
    >
      <Icon name="Close" />
    </button>

  </div>
);


const ExtraFilters = ({ params, removeFilter }) => {
  const {
    referrer, url, country, device, language, utmSource, utmMedium, utmCampaign,
  } = params;
  const filters = [];
  console.log({ params }, utmSource);
  if (referrer) {
    filters.push({ ...referrer.extra, id: 'referrer' });
  }
  if (utmSource) {
    filters.push({ ...utmSource.extra, id: 'utmSource' });
  }
  if (utmCampaign) {
    filters.push({ ...utmCampaign.extra, id: 'utmCampaign' });
  }
  if (utmMedium) {
    filters.push({ ...utmMedium.extra, id: 'utmMedium' });
  }
  if (language) {
    filters.push({ ...language.extra, id: 'language' });
  }
  if (url) {
    filters.push({ ...url.extra, id: 'url' });
  }
  if (device) {
    filters.push({ ...device.extra, id: 'device' });
  }
  if (country) {
    filters.push({ ...country.extra, id: 'country' });
  }
  if (filters.length > 0) {
    return (
      <div className={css.container}>
        {filters.map(filter => (
          <Filter {...filter} removeFilter={removeFilter} key={filter.name} />
        ))}
      </div>
    );
  }
  return null;
};

export default ExtraFilters;
