import React, { useRef } from 'react';
import ApexChart from 'react-apexcharts';
import useComponentSize from '@rehooks/component-size';
import {
  format, startOfDay, endOfDay,
} from 'date-fns';
import { useColors } from 'hooks/useTheme';
import numeral from 'numeral-es6';
import { useAnimations } from 'hooks/useUser';
import css from './style.module.scss';

const createTooltip = colors => ({
  series, dataPointIndex, w,
}) => {
  const tooltip = ['<div class="apex-tooltip">'];
  const firstDate = w.config.series[0].categories[dataPointIndex];
  tooltip.push(`<div class='date'>${firstDate}</div><div class="body">`);
  w.config.series.forEach((serie, index) => {
    tooltip.push(`<div class="group">
      <div class="row">
        <div class="point" style="background: ${colors[index]}"></div>
        <div class="title">${w.config.series[index].name}</div>
        <div class="value">
          ${series[index][dataPointIndex]}
        </div>
      </div>
      </div>`);
  });
  tooltip.push('<span>Click to filter by day</span>');
  tooltip.push('</div></div>');

  return tooltip.join('');
};

const Chart = ({
  data, params, title, xaxis, onChangeDate,
}) => {
  const ref = useRef(null);
  const colors = useColors();
  const animate = useAnimations();
  const { width } = useComponentSize(ref);
  const chartHeight = '350px';

  const chartOpts = {
    colors: [colors.warnColor100, colors.secondaryColor100],
    lineColors: [colors.warnColor100, colors.secondaryColor100],
  };
  const options = {
    chart: {
      stacked: false,
      animations: {
        enabled: animate,
      },
      toolbar: {
        show: false,
      },
      events: {
        markerClick: (
          event,
          chartContext,
          { seriesIndex, dataPointIndex },
        ) => {
          if (params.timeSection === 'day') {
            const dateString = chartContext.opts.series[seriesIndex].categories[dataPointIndex];
            const [y, m, d] = dateString.split('-');
            const date = new Date(y, parseInt(m) - 1, d);
            const dates = {
              from: startOfDay(date),
              to: endOfDay(date),
              label: 'single_day',
            };
            onChangeDate(dates);
          }
        },
      },
    },

    stroke: {
      curve: 'smooth',
      width: 4,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%',

      },
    },
    grid: {
      enabled: false,
      borderColor: 'transparent',
      row: {
        enabled: false,
        colors: ['var(--border-color)', 'transparent'], // takes an array which will be repeated on columns
      },
      padding: {
        right: 14,
      },
    },
    fill: {
      // enabled: false,
      // opacity: [1, 0.5],
      colors: chartOpts.lineColors,
    // type: "gradient",
      // gradient: {
      //   shade: "light",
      //   type: "vertical",
      //   // shadeIntensity: 1,
      //   opacityFrom: 1,
      //   opacityTo: 0.4,
      //   stops: [0, 90, 100]
      // }
      // opacity: [0.3, 0.3],
      // gradient: {
      //   enabled: true,
      //   opacityFrom: 1,
      //   opacityTo: 0.0
      // }
    },
    colors: chartOpts.lineColors,
    // markers: {
    //   // show: false,
    //   size: 3,
    //   colors: chartOpts.lineColors,
    //   strokeColor: chartOpts.lineColors,
    //   // strokeColor: [
    //   //   colors.backgroundColor,
    //   //   colors.backgroundColor,
    //   //   colors.secondaryColor100,
    //   //   colors.accentColor100
    //   // ],
    //   // strokeWidth: 3,
    //   // fillOpacity: 0,
    //   hover: {
    //     size: 5
    //   }
    // },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: true,
        height: 2,
        color: colors.borderColor,
      },
      axisTicks: {
        show: true,
        color: colors.textColor,
      },
      categories: xaxis,
      labels: { show: true },
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    yaxis: {
      min: 0,
      axisBorder: {
        show: false,
      },
      forceNiceScale: true,
      tickAmount: 5,
      labels: {
        formatter(val) {
          return numeral(val).format('0');
        },
      },
    },
    zoom: false,
    tooltip: {
      custom: createTooltip(chartOpts.lineColors),
      y: {
        formatter(val) {
          return `${val} ${title}`;
        },
      },
      x: {
        formatter(val) {
          const f = params.timeSection === 'day'
            ? 'DD-MM-YYYY HH:mm'
            : 'DD-MM-YYYY HH:mm';
          return format(val, f);
        },
      },
    },
  };

  return (
    <div ref={ref} className={css.chartContainer}>
      {width > 0 && (
        <ApexChart
          options={options}
          series={data}
          height={chartHeight}
        />
      )}
    </div>
  );
};

export default Chart;
