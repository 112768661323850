import React, { useRef } from 'react';
import { SectionTitle, SectionSubTitle } from 'components/ui/Typography';
import { saveAs } from 'file-saver';
import cn from 'classnames';
import Icon from 'components/ui/Icon';
import Button from 'components/ui/Button';
import html2canvas from 'html2canvas';
import { downloadCSV } from 'shared/csv';
import css from './style.module.scss';

export const DownloadPngButton = ({
  node, name, className, downloadData,
}) => {
  const onClick = () => {
    if (downloadData) {
      downloadCSV(downloadData(), name);
      return;
    }
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const el = node.current.parentElement.parentElement;
    const { top, left } = el.getBoundingClientRect();
    html2canvas(el, {
      backgroundColor: null,
      y: scrollTop + top,
      x: scrollLeft + left,
    }).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, `${name}.png`);
      });
    });
  };
  return (
    <Button
      link
      alt="Download as CSV"
      className={cn(css.downloadLink, className)}
      onClick={onClick}
    >
      <Icon name="Download" size="10" />
      {/* Download */}
    </Button>
  );
};

const SectionHeader = ({
  title,
  subtitle,
  download,
  downloadFileName,
  className,
  downloadData,
}) => {
  const nodeRef = useRef();
  return (
    <div ref={nodeRef} className={cn(css.sectionHeader, className)}>
      <div className={css.titles}>
        <SectionTitle>{title}</SectionTitle>
        <SectionSubTitle>{subtitle}</SectionSubTitle>
      </div>
      {download && (
        <DownloadPngButton
          downloadData={downloadData}
          node={nodeRef}
          name={downloadFileName}
        />
      )}
    </div>
  );
};

export default SectionHeader;
