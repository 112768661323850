import { render } from 'preact';
import Raven from 'raven-js';
import App from './App';


const startApp = () => {
  render(<App />, document.getElementById('root'));
  window.scrollTo(0, 1);
};

if (process.env.PREACT_APP_ENV !== 'DEVELOPMENT') {
  Raven.config(process.env.PREACT_APP_SENTRY_URL, {
    release: process.env.PREACT_APP_ENV,
    captureUnhandledRejections: true,
  }).install();
  Raven.context(() => {
    startApp();
  });
} else {
  startApp();
}
