import React from 'react';
import useFetch from 'hooks/useFetch';
import { prepareParams } from 'components/apps/Details/DataLoader';
import cn from 'classnames';
import {
  Title,
  SubTitle,
} from 'components/ui/Typography';
import numeral from 'numeral-es6';
import css from './style.module.scss';

const formatNumber = (value, _format) => {
  const format = _format || (value.length > 6 ? '0,0a' : '0,0');
  return numeral(value).format(format);
};
const NumberBox = ({
  value, label, suffix, format,
}) => (
  <div className={cn(css.box)}>
    <SubTitle className={css.title}>{label}</SubTitle>
    <Title className={css.number}>
      {formatNumber(value, format)}
      {' '}
      <small>{suffix}</small>
    </Title>
  </div>
);

export const Info = ({ app, params }) => {
  const { response } = useFetch(
    `/apps/${app.uuid}/visits/info`,
    {
      params: prepareParams(params),
      defaultResponse: {
        sessions: '-',
        visits: '-',
      },
    },
  );
  const {
    response: currentUsers,
  } = useFetch(`/apps/${app.uuid}/visits/realtime`, {
    params: prepareParams(params),
    defaultResponse: '-',
    map: _response => _response.currentUsers,
  });

  // if (loading || realTimeLoading) {
  //   return (
  //     <div className={cn(css.infoContainer)}>
  //       <Loading />
  //     </div>
  //   );
  // }
  // if (error || realTimeError) {
  //   return <Error />;
  // }
  return (
    <div className={cn(css.infoContainer)}>
      <div className={cn(css.boxContainer, css.green)}>
        <div className={css.numberOfUsers}>
          {currentUsers}
          {' '}
          <span>{parseInt(currentUsers) === 1 ? 'visit' : 'visits'}</span>
        </div>
        <div className={css.description}>
          on
          {' '}
          <b>{app.name}</b>
          {' '}
right now
        </div>
      </div>
      <div className={cn(css.boxContainer, css.other)}>
        <SubTitle>In the last 30 minutes...</SubTitle>
        <div className={css.flex}>
          <NumberBox value={response.sessions} label="New Visits" />
          <NumberBox value={response.visits} label="Pageviews" />
        </div>
      </div>
    </div>
  );
};

export default Info;
