import { useEffect, useState } from 'react';

export default () => {
  const [stripe, setStripe] = useState(undefined);
  useEffect(() => {
    const script = document.createElement('script');
    script.onload = () => {
      setStripe(window.Stripe(process.env.PREACT_APP_STRIPE_KEY));
    };
    script.src = 'https://js.stripe.com/v3/';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return stripe;
};
