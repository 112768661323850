import React from "react";
import Redirect from "components/ui/Redirect";
import useUser from "hooks/useUser";

const Blocker = ({ children, can, redirectTo }) => {
  const user = useUser();
  if (can({ user })) {
    return children;
  }
  return <Redirect noThrow to={redirectTo} />;
};

export default Blocker;
