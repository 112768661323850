import React, { useState } from 'react';
import cn from 'classnames';
import useOnClickOutside from 'use-onclickoutside';
import { SectionTitle } from 'components/ui/Typography';
import Icon from 'components/ui/Icon';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'components/ui/Button';
import css from './style.module.scss';
import DatesDisplayer from './DatesDisplayer';
import ShortcutsList from './ShortcutsList';
import Datepicker from './Datepicker';

const isMobile = () => document.body.clientWidth < 750;

const Panel = ({ children, className, onClose }) => {
  const ref = React.useRef(null);
  // useOnClickOutside(ref, () => {
  //   !isMobile() && onClose();
  // });
  return (
    <div ref={ref} className={cn(css.panel, className)}>
      {children}
    </div>
  );
};

export default ({
  from, to, onChangeDate, mobile, desktop,
}) => {
  // const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  // const [dates, setDates] = useState({ from, to });
  const dates = useSelector(state => ({
    from: state.app.filters.from,
    to: state.app.filters.to,
    label: state.app.filters.label || 'last_14_days',
  }));

  // const [currentShortcut, setCurrentShortcut] = useState("last_14_days");
  const onDateChange = (_dates) => {
    // setOpen(false);
    // setDates(_dates);
    // setCurrentShortcut(_dates.label);
    setOpen(false);
    onChangeDate(_dates);
  };
  const close = () => {
    onChangeDate(dates);
    setOpen(false);
  };
  const togglePanel = () => {
    setOpen(true);
  };
  return (
    <div className={cn(css.datePickerContainer, desktop && css.desktop, mobile && css.mobile)}>
      <DatesDisplayer
        from={dates.from}
        to={dates.to}
        currentShortcut={dates.label}
        onClick={togglePanel}
      />
      {open && (
        <Panel
          className={css.dateTimeContainer}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className={css.header}>
            <SectionTitle>Select a range</SectionTitle>
            <button
              className={css.closeButton}
              type="button"
              onClick={() => setOpen(false)}
            >
              <Icon name="Close" />
            </button>
          </div>
          <div className={css.wrapper}>
            <Datepicker from={dates.from} to={dates.to} handleSelect={onDateChange} />
            <ShortcutsList
              onDateChange={(_dates) => {
                onDateChange(_dates);
              }}
              currentShortcut={dates.label}
            />
          </div>
        </Panel>
      )}
    </div>
  );
};
