import {
  subDays,
  eachDay,
  format,
  endOfDay,
  startOfDay,
  differenceInHours,
  differenceInMinutes,
  addHours,
  addMinutes,
} from 'date-fns';

const eachHour = (from, to) => {
  const output = [];
  const totalHours = differenceInHours(to, from) + 1;
  for (let i = 0; i < totalHours; i++) {
    output.push(addHours(from, i));
  }
  return output;
};
const eachMinute = (from, to) => {
  const output = [];
  const totalHours = differenceInMinutes(to, from) + 1;
  for (let i = 0; i < totalHours; i++) {
    output.push(addMinutes(from, i));
  }
  return output;
};

export const timeSections = {
  day: {
    each: eachDay,
    format: 'YYYY-MM-DD',
  },
  hour: {
    each: eachHour,
    format: 'YYYY-MM-DD HH:mm:ss',
  },
  minute: {
    each: eachMinute,
    format: 'YYYY-MM-DD HH:mm:00',
  },
};

export const normalizeChartData = (data, params = {}) => {
  const sectionOpts = timeSections[params.timeSection || 'day'];
  let empty = true;
  let output = [];
  const endOfPeriod = params.timeSection === 'minute'
    ? params.to
    : endOfDay(params.to || new Date());
  output = sectionOpts
    .each(params.from || startOfDay(subDays(new Date(), 14)), endOfPeriod)
    .reduce((acc, d) => {
      const formattedDate = format(d, sectionOpts.format);
      acc.push([formattedDate, 0]);
      return acc;
    }, []);
  data.forEach((day) => {
    const formattedDate = format(new Date(day.date), sectionOpts.format);
    const d = output.find(_day => _day[0] === formattedDate) || [];
    empty = !day.count;
    d[1] = day.count;
  });
  return {
    empty,
    data: output,
    elements: output.map(o => o[1]),
    days: output.map(o => o[0]),
  };
};

export default {};
