import React, { useState } from 'react';
import { Input } from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import { useDispatch } from 'react-redux';
import { toast } from 'reducers/toasts';
import { setApp } from 'reducers/apps';
import cn from 'classnames';
import {
  SectionTitle, Text,
} from 'components/ui/Typography';
import Icon from 'components/ui/Icon';
import useUser from 'hooks/useUser';
import useFetch from 'hooks/useFetch';
import { saveApp } from './api';
import css from './style.module.scss';

const Ip = ({
  name, ip, id, onChange, onDelete,
}) => {
  const onChangeName = (e) => {
    onChange({ name: e.target.value, ip, id });
  };
  const onChangeIp = (e) => {
    onChange({ name, ip: e.target.value, id });
  };

  return (
    <div className={css.ip}>
      <Input
        className={cn(css.input, css.text)}
        type="text"
        onChange={onChangeName}
        name="ip-name"
        responsive
        placeholder="Work's IP"
        value={name}
      />
      <Input
        className={cn(css.input, css.ip)}
        type="text"
        placeholder="1.1.1.1"
        onChange={onChangeIp}
        responsive
        name="ip-ip"
        value={ip}
      />
      <Button
        link
        nomargin
        className={css.button}
        onClick={() => {
          onDelete(id);
        }}
      >
        <Icon name="Close" />
      </Button>
    </div>
  );
};
const generateId = () => Math.random()
  .toString(36)
  .substr(2, 9);
const Whitelist = ({ app }) => {
  const dispatch = useDispatch();
  const user = useUser();
  const [ips, setIps] = useState(app.whitelist);

  const onChange = (changedIp) => {
    setIps(
      ips.map((ip) => {
        if (ip.id === changedIp.id) {
          return changedIp;
        }
        return ip;
      }),
    );
  };

  const onAdd = (ip) => {
    setIps([...ips, { ...ip, id: generateId() }]);
  };

  const onDelete = (id) => {
    setIps(ips.filter(ip => ip.id !== id));
  };

  const onSave = () => {
    setIps(ips.filter(ip => ip.ip));
    saveApp(app.uuid, {
      name: app.name,
      website: app.website,
      whitelist: ips.filter(ip => ip.ip),
    }).then((response) => {
      dispatch(setApp(response));
      dispatch(toast({ message: 'Filtered IPs updated.' }));
    });
  };
  const { response, loading } = useFetch('/users/me/ip');
  return (
    <div className={css.whitelist}>
      <SectionTitle className={css.title}>
        Filtered IP addresses
      </SectionTitle>
      <Text>
        Add here the IP addresses that you want to filter. Metrical won't
        save visits from these IPs. Useful if you want to filter out your visits or visits from your place of work.
      </Text>
      <Text>
        You can also filter entire sets of IPs, just insert the common part of that IPs. Example: '1.2.' => will filter all IPs like 1.2.X.X.
      </Text>
      <div className={css.ipList}>
        {ips.map(({ name, ip, id }) => (
          <Ip
            name={name}
            onChange={onChange}
            onDelete={onDelete}
            ip={ip}
            id={id}
            key={id}
          />
        ))}
      </div>
      <div>
        <Button
          link
          nomargin
          className={css.addIP}
          onClick={() => {
            onAdd({ name: '', ip: undefined });
          }}
        >
          Add new IP
        </Button>
      </div>
      <Button onClick={onSave} track="settings_update_whitelist" className={css.save}>
        Save
      </Button>
    </div>
  );
};

export default Whitelist;
