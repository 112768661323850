import {
  differenceInCalendarDays,
  subDays,
  format,
} from 'date-fns';

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const isPhone = () => document.body.clientWidth < 750;
export const formatWebsite = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

export const getPreviousPeriod = ({ from, to }) => {
  const difference = differenceInCalendarDays(to, from) + 1;
  return {
    from: format(subDays(from, difference), 'YYYY-MM-DD'),
    to: format(subDays(to, difference), 'YYYY-MM-DD'),
  };
};

export const to = promise => promise.then(data => [null, data]).catch(err => [err]);
