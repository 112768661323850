import React from 'react';
import { Title } from 'components/ui/Typography';
import useUser from 'hooks/useUser';
import DataSection from './Data';
import InvoicesSection from './Invoices';
import css from './style.module.scss';
import StripeSection from './Stripe';
import VisitsSection from './Visits';

export default ({ children }) => {
  const user = useUser();
  return (
    <div>
      <div className={css.settings}>
        <Title>Settings</Title>
        <div className={css.content}>
          <div className={css.left}>
            <DataSection />
          </div>

          <div className={css.right}>
            <VisitsSection />
            <StripeSection user={user} />

          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
