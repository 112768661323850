import { createAction, createReducer } from 'redux-act';

export const setTheme = createAction('setTheme');
export const setRoutePath = createAction('setRoutePath');
const defaultState = { theme: 'light', routePath: '/' };

const darkMode = localStorage.getItem('darkMode');
if (darkMode) {
  defaultState.theme = darkMode === 'true' ? 'dark' : 'light';
}


const setClassOnDocumentBody = (_darkMode) => {
  document.body.classList.add(_darkMode ? 'dark-mode' : 'light-mode');
  document.body.classList.remove(_darkMode ? 'light-mode' : 'dark-mode');
};


export default createReducer(
  {
    [setRoutePath]: (state, payload) => ({
      ...state,
      routePath: payload,
    }),
    [setTheme]: (state, payload) => {
      setClassOnDocumentBody(payload === 'dark');
      localStorage.setItem('darkMode', payload === 'dark');
      return {
        ...state,
        theme: payload,
      };
    },
  },
  defaultState,
);
