import React from 'react';
import { capitalize } from 'shared/utils';
import css from './style.module.scss';
import SectionBarVisualizer from '../SectionBarVisualizer';

const normalizeData = (data, addFilter) => Object.keys(data.widths).reduce(
  (acc, key) => {
    acc.maxValue = acc.maxValue > data.widths[key] ? acc.maxValue : data.widths[key];
    acc.items.push({
      value: capitalize(key),
      count: data.widths[key],
      onClick: () => {
        addFilter('device', key, { value: capitalize(key), name: 'Device' });
      },
    });
    return acc;
  },
  { items: [], max: 1, columns: ['device', 'visits'] },
);

export const Widths = ({
  app, params, color, addFilter,
}) => (
  <div className={css.pages}>
    {/* <SectionHeader
      title="Devices"
      subtitle="With what devices the visitors navigate the page?"
      download
      downloadFileName={`${app.name} - Devices`}
    /> */}

    <SectionBarVisualizer
      withHeader
      header={{
        title: 'Devices',
        subtitle: 'With what devices the visitors navigate the page?',
      }}
      downloadFileName={`${app.name} - Devices`}
      url={`/apps/${app.uuid}/visits/widths`}
      params={params}
      normalizer={normalizeData}
      showPages
      name="device"
      addFilter={addFilter}
      barColor={color}
    />
  </div>
);

export default Widths;
