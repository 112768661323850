import React from 'react';
import Icon from 'components/ui/Icon';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { backFilter, removeFilter } from 'reducers/app';
import Button from 'components/ui/Button';
import { downloadCSV } from 'shared/csv';
import { trackEvent } from 'shared/track';
import css from './style.module.scss';

const FiltersBar = ({
  name,
  page,
  onChangePage,
  hasMoreResults,
  downloadData,
  hasFilter,
  downloadFileName,
}) => {
  const dispatch = useDispatch();
  const filter = useSelector(state => state.app.filters[name]);
  const previous = filter && filter.previous;
  const onClickBack = () => {
    if (previous.remove) {
      dispatch(removeFilter(name));
    } else {
      dispatch(backFilter(name));
    }
  };
  const onClickDownload = () => {
    if (downloadData) {
      downloadCSV(downloadData(), downloadFileName || name);
      trackEvent('download_csv_data', { type: name });
    }
  };
  const showPage = page > 0 ? true : hasMoreResults;
  return (
    <div className={css.filtersBar}>
      {previous && (
        <div onClick={onClickBack} className={css.back}>
          <Icon name="Back" />
        </div>
      )}

      {filter && <div className={css.name}>{filter.extra.value}</div>}
      {hasFilter && !filter && (
        <div className={css.filterTip}>Click on a row to filter</div>
      )}
      {showPage && (
        <div className={css.page}>
          <Icon
            onClick={() => page !== 0 && onChangePage(true)}
            name="ArrowLeft"
            className={cn(css.arrow, page === 0 && css.disabled)}
          />
          <div className={css.pageNumber}>
            Page
            {` ${page + 1}`}
          </div>
          <Icon
            onClick={() => hasMoreResults && onChangePage(false)}
            name="ArrowRight"
            className={cn(css.arrow, !hasMoreResults && css.disabled)}
          />
        </div>
      )}
      {downloadData && (
        <Button
          link
          alt="Download as CSV"
          className={cn(css.downloadLink, !showPage && css.marginLeftAuto)}
          onClick={onClickDownload}
        >
          <Icon name="Download" size="10" />
          {/* Download */}
        </Button>
      )}
    </div>
  );
};

export default FiltersBar;
