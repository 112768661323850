import React from 'react';
import { Link } from 'preact-router';
import cn from 'classnames';
import Icon from 'components/ui/Icon';
import getApp from 'hooks/useApp';
import useLocation from 'hooks/useLocation';
import css from './style.module.scss';

const Item = ({
  to, icon, path, children, mobileHide,
}) => (
  <Link

    href={to}
    className={cn(
      css.item,
      path === to && css.itemSelected,

      mobileHide && css.mobileHide,
    )}
  >
    <div className={css.iconWrapper}>
      <Icon name={icon} />
    </div>
    <div className={css.navTitle}>{children}</div>
  </Link>
);
export const Navigation = ({ demo, uuid }) => {
  const app = getApp(uuid);
  const prefix = demo ? '/demo' : `/a/${uuid}`;
  const currentUrl = useLocation();
  if (!demo && !app) {
    return null;
  }
  return (
    <div className={css.navigation}>
      <Item path={currentUrl} to={prefix} icon="BarChart">
        General
      </Item>
      <Item path={currentUrl} to={`${prefix}/current`} icon="Current">
        Real Time
      </Item>
      {!demo && app.userRole === 'owner' && (
        <Item
          path={currentUrl}
          to={demo ? '/demo/register' : `${prefix}/settings`}
          icon="Settings"
        >
          Settings
        </Item>
      )}
    </div>
  );
};

export default Navigation;
