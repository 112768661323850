/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import cn from 'classnames';
import Button from 'components/ui/Button';
import useUser from 'hooks/useUser';
import css from './style.module.scss';

const Item = ({
  event, selected, onClick, selectEvent,
}) => (
  <button
    type="button"
    className={cn(css.eventItem, selected && css.selected)}
    onClick={() => onClick && onClick(event)}
  >
    <span
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        selectEvent(event);
        return false;
      }}
      className={css.selectedCircle}
      style={selected && { background: selected.color }}
    />
    <span
      className={css.type}
    >
      {event.name}
    </span>
    <span
      className={css.events}
    >
      {event.count}
      {' '}
      {parseInt(event.count, 10) === 1 ? 'event' : 'events'}
    </span>
  </button>
);

export const EventsList = ({
  events,
  app,
  selectedEvents,
  onEventSelected,
  openNewPanel,
}) => {
  const user = useUser();

  const [eventsToShow, setEventsToShow] = useState(6);
  return (
    <div className={css.events}>
      <div className={css.eventsList}>
        <div className={css.eventsHeader}>
          <p className={css.info}>You can select up to 4 events to view them in the chart</p>
          {!user.demo && app.userRole === 'owner'
          && (
          <Button
            secondary
            className={css.button}
            onClick={() => {
              openNewPanel(true);
            }}
          >
Create Event

          </Button>
          )
}
        </div>

        {events.slice(0, eventsToShow).map(e => (
          <Item
            key={e.type}
            event={e}
            selectEvent={onEventSelected}
            selected={selectedEvents.find(event => e.type === event.type)}
          />
        ))}
        {eventsToShow < events.length
        && (
          <div className={css.eventsFooter}>

            <Button
              link
              className={css.button}
              onClick={() => {
                setEventsToShow(events.length);
              }}
            >
Show all events (
              {events.length}
)

            </Button>

          </div>

        )
}
        <p className={css.info}>
How to track an event? Read our
          {' '}
          {' '}
          <Button link external to="https://docs.metrical.xyz/developers/how-to-track-events">Documentation</Button>
        </p>


      </div>

    </div>

  );
};

export default EventsList;
