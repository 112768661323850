import React from 'react';
import useFetch from 'hooks/useFetch';
import { prepareParams } from 'components/apps/Details/DataLoader';
import cn from 'classnames';
import { Title, SubTitle, Text } from 'components/ui/Typography';
import Loading from 'components/ui/Loading';
import Error from 'components/ui/Error';
import ReactTooltip from 'react-tooltip';
import numeral from 'numeral-es6';
import css from './style.module.scss';

const renderTime = (_timeShared) => {
  if (_timeShared === 0) {
    return '0 seconds';
  }
  let timeShared = _timeShared;
  const days = Math.floor(timeShared / 86400);
  if (days) {
    timeShared -= days * 86400;
  }

  // calculate (and subtract) whole hours
  const hours = Math.floor(timeShared / 3600) % 24;
  if (hours) {
    timeShared -= hours * 3600;
  }

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(timeShared / 60) % 60;
  if (minutes) {
    timeShared -= minutes * 60;
  }

  // what's left are seconds
  const seconds = timeShared % 60;
  let s = '';
  s += days ? days + (days >= 2 ? ' days, ' : ' day, ') : '';
  s += hours ? hours + (hours >= 2 ? ' hours, ' : ' hour, ') : '';
  s += minutes
    ? minutes + (minutes >= 2 ? ' minutes and ' : ' minute and ')
    : '';
  s += seconds ? `${parseInt(seconds)} seconds` : '';
  return s;
};

const getPercentage = (value, previous) => (value - previous) / Math.max(previous, 1);
const formatNumber = (value, _format) => {
  if (_format === 'time') {
    return renderTime(value);
  }
  const format = _format || (value.length > 6 ? '0,0a' : '0,0');
  return numeral(value).format(format);
};
const NumberBox = ({
  value, label, previous, format,
}) => {
  const perc = getPercentage(value, previous);
  const percText = `${(perc >= 0 ? '+' : '') + numeral(perc).format('0,0%')}`;
  return (
    <div className={css.box}>
      <SubTitle className={css.title}>{label}</SubTitle>
      <div className={css.text}>
        <Title className={css.number}>{formatNumber(value, format)}</Title>
        {previous && (
          <Text className={css.previous}>
            <span
              data-tip={`Previous period:  ${previous} ${label}`}
              className={cn(
                css.dash,
                css.previous,
                perc < 0 && css.negative,
                perc > 0 && css.positive,
              )}
            >
              {percText}
            </span>
            {' '}
            from previous period
          </Text>
        )}
      </div>
    </div>
  );
};

export const Info = ({ app, params }) => {
  const { response, error, loading } = useFetch(
    `/apps/${app.uuid}/visits/info`,
    { params: prepareParams(params) },
  );
  const {
    response: previousResponse,
    error: previousErr,
    loading: previousLoading,
  } = useFetch(`/apps/${app.uuid}/visits/info`, {
    params: prepareParams({ ...params, ...params.previous }),
  });

  if (loading || previousLoading) {
    return <Loading />;
  }
  if (error || previousErr) {
    return <Error />;
  }
  return (
    <div className={cn(css.infoContainer)}>
      <ReactTooltip place="top" effect="solid" />
      <div className={css.info}>
        <NumberBox
          value={response.sessions}
          label="Unique visits"
          previous={previousResponse && previousResponse.sessions}
        />
        <NumberBox
          value={response.visits}
          label="Pageviews"
          previous={previousResponse && previousResponse.visits}
        />

        <NumberBox
          value={response.avgTime}
          label="Average time on page"
          // suffix="seconds"
          format="time"
          // previous={previousResponse && previousResponse.avgTime}
        />
      </div>
    </div>
  );
};

export default Info;
