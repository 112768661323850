import React, { useState } from 'react';
import Flex from 'components/ui/Flex';
import Box from 'components/ui/Box';
import cn from 'classnames';
import { useColors } from 'hooks/useTheme';
import { SectionTitle, Text } from 'components/ui/Typography';
import Button from 'components/ui/Button';
import css from './style.module.scss';
import CurrentUsersSection from './sections/CurrentUsers';
import InfoSection from './sections/Info';
import LanguagesSection from './sections/Languages';
import CountriesSection from './sections/Countries';
import SourcesSection from './sections/Sources';
import WidthsSection from './sections/Widths';
import PagesSection from './sections/Pages';
import VisitsSection from './sections/Visits';
import EventsSection from './sections/Events';
import UtmSection from './sections/UTM';


const NoVisitsPanel = ({ app }) => (
  <div className={css.noVisitsPanel}>
    <div className={css.inner}>
      <img src="https://media.giphy.com/media/g01ZnwAUvutuK8GIQn/giphy-downsized.gif" />
      <div>
        <SectionTitle className={css.noVisitsTitle}>
            This website has no visits... yet!
        </SectionTitle>

        <Text>
            Seems like nobody visited your website since your first setup on
          {' '}
          <b>Metrical</b>
.
          <br />
          <br />
          {' '}
          <br />
            Try to:
          <ul>
            <li>
              <Button link to={`/a/${app.uuid}/settings`}>
                  Check
              </Button>
              {' '}
                if you added the script in the right way
            </li>
            <li>
              <Button link external to={app.website}>
                  Visit the website
              </Button>
              {' '}
                right now to create your first visit!
            </li>
          </ul>
        </Text>
      </div>
      {/* <SectionSubTitle>
          Have you added the script to the website?
        </SectionSubTitle> */}
    </div>
  </div>
);

export default ({
  app, params, addFilter, onChangeDate,
}) => {
  const [compare, toggleCompare] = useState(false);
  const colors = useColors();

  return (
    <div className={css.container}>
      {app.hasVisits && (
        <>
          <Flex className={cn(css.sectionContainer)} tabletResponsive>
            <Box size="large" first>
              <Flex tabletResponsive>
                <div style={{ minWidth: '70%' }}>
                  <VisitsSection
                    app={app}
                    onChangeDate={onChangeDate}
                    toggleCompare={toggleCompare}
                    compare={compare}
                    params={params}
                  />
                  <CurrentUsersSection app={app} params={params} />
                </div>
                <InfoSection
                  app={app}
                  params={params}
                  toggleCompare={toggleCompare}
                  compare={compare}
                />
              </Flex>
            </Box>
          </Flex>

          <Flex className={css.sectionContainer} tabletResponsive>
            <Box>
              <PagesSection
                color={colors.lightBlue100}
                app={app}
                params={params}
                addFilter={addFilter}
              />
            </Box>
            <Box>
              <WidthsSection
                color={colors.warnColor100}
                app={app}
                params={params}
                addFilter={addFilter}
              />
            </Box>
          </Flex>
          <Flex className={css.sectionContainer} tabletResponsive>
            <Box>
              <SourcesSection
                color={colors.secondaryColor10}
                app={app}
                params={params}
                addFilter={addFilter}
              />
            </Box>
            <Box>
              <EventsSection
                // color={colors.secondaryColor10}
                app={app}
                params={params}
                addFilter={addFilter}
              />
            </Box>
          </Flex>
          <Flex className={css.sectionContainer} tabletResponsive>
            <Box>
              <CountriesSection
                color={colors.warnColor10}
                app={app}
                params={params}
                addFilter={addFilter}
              />
            </Box>
            <Box>
              <LanguagesSection
                color={colors.purple100}
                app={app}
                params={params}
                addFilter={addFilter}
              />
            </Box>
          </Flex>
          <Flex className={css.sectionContainer} tabletResponsive>

            <Box>
              <UtmSection
                colors={{ sources: colors.warnColor10, campaigns: colors.lightBlue100, medium: colors.purple100 }}
                app={app}
                params={params}
                addFilter={addFilter}
              />
            </Box>
          </Flex>
        </>
      )}
      {!app.hasVisits && <NoVisitsPanel app={app} />}
    </div>
  );
};
