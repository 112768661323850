import React from 'react';
import { connect } from 'react-redux';
import css from './style.module.scss';
import Toast from './toast';

const ToastManager = ({ toasts }) => (
  <div className={css.toastManager}>
    {toasts.map(t => (
      <Toast
        key={t.id}
        duration={t.duration}
        important={t.important}
        onClick={t.onClick}
        id={t.id}
      >
        {t.message}
      </Toast>
    ))}
  </div>
);

const mapStateToProps = state => ({
  toasts: state.toasts,
});

export default connect(mapStateToProps)(ToastManager);
