import React from 'react';
import cn from 'classnames';
import css from './style.module.scss';

export const Favicon = ({
  url, size = 'normal', className, miniImage,
}) => {
  const classes = cn(css.favicon, css[size], className);
  return (
    <div className={classes}>
      <img className={css.image} src={url} alt="favicon" />
      {miniImage && <img src={miniImage} alt="owner avatar" className={css.miniImage} />}
    </div>
  );
};

export default Favicon;
