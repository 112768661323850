import React from 'react';
import cn from 'classnames';
import LazyLoad from 'react-lazyload';
import css from './style.module.scss';

export const Box = ({
  children,
  className,
  style,
  first,
  size = 'medium',
  lazy = true,
  lazyHeight,
  noBorder,
}) => {
  const classes = cn(

    css.box,
    css[size],
    first && css.first,
    noBorder && css.noBorder,
    className,
  );
  if (lazy) {
    return (
      <div className={classes} style={style}>
        <LazyLoad once height={lazyHeight || 20}>
          {children}
        </LazyLoad>
      </div>
    );
  }
  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export default Box;
