import { client } from 'shared/api';

export const createApp = async ({ name, website }) => {
  const response = await client().post('/apps', { name, website });
  return response.data;
};

export default {
  createApp,
};
