import { createStore, combineReducers, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import userReducer from './user';
import toastsReducer from './toasts';
import appsReducer from './apps';
import appReducer from './app';
import uiReducer from './ui';

const reducers = combineReducers({
  // ...your other reducers here
  // you have to pass formReducer under 'form' key,
  // for custom keys look up the docs for 'getFormState'
  user: userReducer,
  apps: appsReducer,
  app: appReducer,
  toasts: toastsReducer,
  ui: uiReducer,
});

const sideEffects = {};

const sideEffectsMiddleware = store => next => (action) => {
  const returnedValue = next(action);
  const currentState = store.getState();
  Object.keys(sideEffects).forEach((key) => {
    if (sideEffects[key][action.type]) {
      sideEffects[key][action.type](action, currentState);
    }
  });
  return returnedValue;
};

export const store = createStore(
  reducers,
  process.env.PREACT_APP_ENV !== 'production'
    ? applyMiddleware(sideEffectsMiddleware, logger)
    : applyMiddleware(sideEffectsMiddleware),
);

export default {
  store,
};
