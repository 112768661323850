import React, { useState } from 'react';
import {
  SectionTitle, Text,
} from 'components/ui/Typography';

import { Formik, Field } from 'formik';
import { Input } from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import { useDispatch } from 'react-redux';
import { toast } from 'reducers/toasts';
import useFetch from 'hooks/useFetch';
import cn from 'classnames';
import * as Yup from 'yup';
import css from './style.module.scss';

import { addDomain, checkDomain } from './api';


const domainSchema = Yup.object().shape({
  domain: Yup.string().required('Required').url('Is this a domain?'),
});

export default ({ app }) => {
  const dispatch = useDispatch();
  const [domain, setDomain] = useState(undefined);
  const [checkLoading, setLoading] = useState(false);
  useFetch(`/apps/${app.uuid}/domains`, {
    map: (data) => {
      // this is really ugly. but it's working 🤷‍♀️
      if (data.length > 0) {
        setDomain(data[0]);
      }
      return null;
    },
  });
  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    await addDomain(app.uuid, values.domain);
    dispatch(toast({ message: 'Domain added.' }));
  };

  const onCheckDomain = async () => {
    setLoading(true);
    const response = await checkDomain(app.uuid, domain.id);
    setLoading(false);
    setDomain(response);
  };
  return (
    <div className={css.domain}>
      <SectionTitle className={css.title}>Custom domain</SectionTitle>
      <Text>
        To prevent Ad-blockers to block user visits, you can setup a custom
        domain and use it in the Tracking script instead of
        {' '}
        <b>cdn.metrical.xyz</b>
.
      </Text>
      {domain && (
        <div className={css.domainInfo}>
          <div
            className={cn(
              css.status,
              domain.completedAt ? css.ready : css.not_ready,
            )}
          />
          {/* <div className={css.cname}>CNAME</div> */}
          {domain.domain}
          <div
            className={cn(
              css.info,
              domain.completedAt ? css.ready : css.not_ready,
            )}
          >
            {checkLoading ? (
              'Loading...'
            ) : domain.completedAt ? (
              'Ready'
            ) : (
              <Button link onClick={onCheckDomain}>
                Check now
              </Button>
            )}
          </div>
        </div>
      )}
      {!domain && (
        <Formik
          onSubmit={onSubmit}
          validationSchema={domainSchema}

          initialValues={{ domain: '' }}
          render={({ handleSubmit, isSubmitting, errors }) => (
            <form onSubmit={handleSubmit} className={css.form}>
              <Field
                errors={errors.domain}

                placeholder="track.yourcustomdomain.com"
                component={Input}
                name="domain"
                type="domain"
                label="Custom domain"
              />
              <Button type="submit" disabled={isSubmitting}>
                Add domain
              </Button>
            </form>
          )}
        />
      )}
      <br />
      <Button
        to="https://docs.metrical.xyz/developers/custom-domain"
        link
        external
      >
        More information
      </Button>
    </div>
  );
};
