import React from 'react';
import { Title, SubTitle } from 'components/ui/Typography';
import Button from 'components/ui/Button';
import css from './style.module.scss';

const AppInfo = ({
  name, website, uuid, archivedAt,
}) => (
  <div className={css.appInfo}>
    <Title className={css.title}>
      {name}
      {archivedAt && (
        <Button link to={`/a/${uuid}/settings`} className={css.archived}>
          Archived
        </Button>
      )}
    </Title>
    <SubTitle className={css.subtitle}>{website}</SubTitle>

    {/* <h1 className={css.name}>{name}</h1>
    <a className={css.website} target="_blank" href={website}>
      {website}
    </a> */}
  </div>
);
export default AppInfo;
