import React from 'react';
import css from './style.module.scss';
import SectionHeader from '../../../SectionHeader';
import SourceBarChart from './SourceBarChart';
import SourceSparkline from './SourceSparkline';

export const SourceDetails = ({
  current,
  name,
  params,
  color,
  app,
  addFilter,
  description,
}) => {
  const p = { ...params, group: { value: 'sessions' } };
  return (
    <div className={css.sourceBarChart}>
      <SectionHeader
        subtitle={description}
        title={name}
        className={css.sourceBarTitle}
        download={false}
      />
      <SourceSparkline
        app={app}
        params={p}
        current={current}
        name={name}
        color={color}
      />
      {current !== 'direct' && (
        <SourceBarChart
          params={p}
          app={app}
          addFilter={addFilter}
          current={current}
          name={name}
          color={color}
        />
      )}
    </div>
  );
};

export default SourceDetails;
