import React, { useRef } from 'react';
import ApexChart from 'react-apexcharts';

import useComponentSize from '@rehooks/component-size';
import { format } from 'date-fns';
import { useAnimations } from 'hooks/useUser';
import numeral from 'numeral-es6';
import { normalizeChartData } from 'shared/visits';
import useFetch from 'hooks/useFetch';
import css from './style.module.scss';
import { prepareParams } from '../../../DataLoader';


const createTooltip = colors => ({
  series, seriesIndex, dataPointIndex, w,
}) => {
  const tooltip = ['<div class="apex-tooltip">'];
  const firstDate = w.config.series[seriesIndex].data[dataPointIndex][0];
  tooltip.push(`<div class='date'>${firstDate}</div><div class="body">`);
  w.config.series.forEach((serie, index) => {
    tooltip.push(`<div class="group">
      <div class="row">
        <div class="point" style="background: ${colors[index]}"></div>
        <div class="title">${w.config.series[index].name}</div>
        <div class="value">
          ${series[index] ? series[index][dataPointIndex] : 0}
        </div>
      </div>
      </div>`);
  });
  tooltip.push('</div></div>');

  return tooltip.join('');
};

const Chart = ({
  selectedEvents, params, app, colors,
}) => {
  const { response } = useFetch(
    selectedEvents.length && `/apps/${app.uuid}/events/show`,
    {
      defaultResponse: [],
      params: {
        ...prepareParams(params),
        types: selectedEvents.map(e => e.type).join(','),
      },
    },
  );
  const series = [];
  response.forEach((event) => {
    const { data } = normalizeChartData(event.events, params);
    const e = selectedEvents.find(se => se.type === event.type);
    if (e) {
      series.push({
        name: e.name,
        data,
      });
    }
  });
  const ref = useRef(null);
  const animate = useAnimations();
  const size = useComponentSize(ref);
  const { width } = size;
  const chartHeight = '250px';
  const chartOpts = {
    colors,
  };
  const options = {
    chart: {
      animations: {
        enabled: animate,
      },
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
      },
    },
    grid: {
      borderColor: 'transparent',
      row: {
        colors: ['rgba(66, 217, 200, 0.07)', 'transparent'], // takes an array which will be repeated on columns
      },
      padding: {
        right: 0,
      },
    },

    colors: chartOpts.colors,
    // markers: {
    //   size: 5,
    //   colors: chartOpts[chartType].fill.colors,
    //   strokeColor: colors.backgroundColor,
    //   strokeWidth: 3,
    //   fillOpacity: 0,
    //   hover: {
    //     size: 5
    //   }
    // },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      // categories: xaxis,
      labels: { show: true },
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      visible: false,
      show: false,
      position: 'top',
      horizontalAlign: 'right',
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
      tickAmount: 5,
      labels: {
        formatter(val) {
          return numeral(val).format('0,0');
        },
      },
    },
    zoom: false,
    tooltip: {
      custom: createTooltip(chartOpts.colors),

      x: {
        formatter(val) {
          const f = params.timeSection === 'day'
            ? 'DD-MM-YYYY HH:mm'
            : 'DD-MM-YYYY HH:00';
          return format(val, f);
        },
      },
    },
  };

  return (
    <div ref={ref} className={css.chartContainer}>
      {/* {loading && <Loading height="250px" />} */}
      {width > 0 && (
        <ApexChart
          options={options}
          series={series}
          type="line"
          height={chartHeight}
        />
      )}
    </div>
  );
};

export default Chart;
