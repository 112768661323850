import React from "react";
import BarChart from "shared/icons/barChart.svg";
import Settings from "shared/icons/settings.svg";
import AddMonitor from "shared/icons/addMonitor.svg";
import Close from "shared/icons/close.svg";
import Download from "shared/icons/download.svg";
import Launch from "shared/icons/launch.svg";
import Arrow from "shared/icons/arrow.svg";
import ArrowLeft from "shared/icons/arrowLeft.svg";
import ArrowRight from "shared/icons/arrowRight.svg";
import ArrowDown from "shared/icons/arrowDown.svg";
import Back from "shared/icons/back.svg";
import Flag from "shared/icons/flag.svg";
import Current from "shared/icons/current.svg";

const icons = {
  BarChart,
  Settings,
  AddMonitor,
  Close,
  Download,
  Launch,
  Arrow,
  ArrowLeft,
  ArrowRight,
  ArrowDown,
  Current,
  Back,
  Flag,
};
const Icon = ({ name, className, style, onClick }) => {
  const Component = icons[name];
  return <Component onClick={onClick} className={className} style={style} />;
};

export default Icon;
