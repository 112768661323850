import React from 'react';
import Button from 'components/ui/Button';
import {
  SectionTitle, Text,
} from 'components/ui/Typography';
import css from './style.module.scss';

export const RenderScript = ({ app, newApp }) => (
  <div className={css.scriptContainer}>
    {newApp && (
      <SectionTitle className={css.title}>Ready to track!</SectionTitle>
    )}
    <Text className={css.text}>
      Here is the script to track
      {' '}
      <b>{app.name}</b>
: just paste it before the
      end of the
      {' '}
      <b>body</b>
      {' '}
tag.
    </Text>
    <div className={css.code}>
      <pre>
        <code>
          {`
<script>
  window.metrical = {
    "app": "${app.uuid}"
  }
</script>        
<script async
    src="https://cdn.metrical.xyz/script.js"
    type="text/javascript">
</script>
  
`}
        </code>
      </pre>
    </div>
    {newApp && (
      <div>
        <Text className={css.text}>
          If you need it, you can always obtain it again in the settings of the
          application.
        </Text>
        <Button className={css.button} to={`/a/${app.uuid}`}>
          Go to your new application
        </Button>
      </div>
    )}
  </div>
);

export default RenderScript;
