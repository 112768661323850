import React, { useState, useEffect } from 'react';
import { useColors } from 'hooks/useTheme';
import useFetch from 'hooks/useFetch';
import Loading from 'components/ui/Loading';
import { SectionTitle, Text } from 'components/ui/Typography';
import { setEvents } from 'reducers/app';
import { useSelector } from 'react-redux';
import css from './style.module.scss';
import SectionHeader from '../../../SectionHeader';
import { prepareParams } from '../../../DataLoader';
import EventsList from './EventsList';
import Chart from './Chart';
import Info from './Info';
import NewEventPanel from './NewEvent';


export const Events = ({
  app, params,
}) => {
  const [openNewPanel, setOpenNewPanel] = useState(false);

  const [selectedEvents, setSelectedEvents] = useState([]);
  const colors = useColors();
  const { loading } = useFetch(`/apps/${app.uuid}/events`, {
    params: prepareParams(params),
    dispatchAction: setEvents,
    map: _response => _response.events.sort((a, b) => (parseInt(a.count) >= parseInt(b.count) ? -1 : 1)),
  });
  const events = useSelector(state => state.app.events);
  const eventsColors = [
    colors.warnColor100,
    colors.green10,
    colors.purple10,
    colors.lightBlue10,
  ];
  useEffect(() => {
    if (events) {
      setSelectedEvents(events.slice(0, 4));
    }
  }, [events]);
  const onEventSelected = (event) => {
    const eventFound = selectedEvents.find(e => e.type === event.type);
    if (eventFound) {
      return setSelectedEvents(selectedEvents.filter(e => e.type !== event.type));
    }
    if (selectedEvents.length < 4) {
      return setSelectedEvents(selectedEvents.concat([event]));
    }
  };
  const coloredSelectedEvents = selectedEvents.map((e, i) => ({ ...e, color: eventsColors[i] }));
  return (
    <div className={css.sourcesContainer}>
      <SectionHeader
        title="Events"
        down
        download={false}
        subtitle="Every time a user does an action, Metrical can track that Event and displays it here."
      />
      {loading && <Loading />}
      {!loading && events && events.length === 0
        && (
        <Info openNewPanel={setOpenNewPanel} />
        )
      }
      {!loading && events && events.length > 0
      && (
        <>
          <Chart colors={eventsColors} selectedEvents={coloredSelectedEvents} app={app} params={params} />
          <EventsList openNewPanel={setOpenNewPanel} colors={eventsColors} onEventSelected={onEventSelected} app={app} events={events} selectedEvents={coloredSelectedEvents} />
        </>
      )
}
      {openNewPanel && (
      <NewEventPanel
        events={events}
        app={app}
        onClose={() => {
          setOpenNewPanel(false);
        }}
      />
      )}
    </div>
  );
};

export default Events;
