import { client } from 'shared/api';

export const saveApp = async (uuid, { name, website, whitelist }) => {
  const response = await client().put(`/apps/${uuid}`, {
    name,
    website,
    whitelist,
  });
  return response.data;
};

export const archiveApp = async (uuid) => {
  const response = await client().post(`/apps/${uuid}/archive`);
  return response.data;
};

export const deleteApp = async (uuid) => {
  const response = await client().delete(`/apps/${uuid}`);
  return response.data;
};

export const addDomain = async (uuid, domain) => {
  const response = await client().post(`/apps/${uuid}/domains`, {
    domain,
  });
  return response.data;
};

export const checkDomain = async (uuid, domainId) => {
  const response = await client().get(`/apps/${uuid}/domains/${domainId}`);
  return response.data;
};

export const inviteCollaborator = async (uuid, email) => {
  const response = await client().post(`/apps/${uuid}/collaborators`, {
    email,
  });
  return response.data;
};

export const removeCollaborator = async (uuid, email) => {
  const response = await client().post(`/apps/${uuid}/collaborators/remove`, {
    email,
  });
  return response.data;
};

export default {
  saveApp,
  inviteCollaborator,
  addDomain,
  checkDomain,
  archiveApp,
  deleteApp,
};
