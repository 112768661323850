import { client } from 'shared/api';

export const register = async (values) => {
  const response = await client().post('/users', values);
  return response.data;
};
export const addToInviteList = async ({ email }) => {
  const response = await client().post('/invites', { email });
  return response;
};

export default {
  register,
  addToInviteList,
};
