import { createAction, createReducer } from 'redux-act';

export const addFilter = createAction('addFilter');
export const addRawFilters = createAction('addRawFilters');
export const setupFilters = createAction('setupFilters');
export const backFilter = createAction('backFilter');
export const removeFilter = createAction('removeFilter');
export const resetFilters = createAction('resetFilters');

export const setEvents = createAction('setEvents');
export const removeEvent = createAction('removeEvent');
export const addEvent = createAction('addEvent');
export const editEvent = createAction('editEvent');

export const setFunnels = createAction('setFunnels');
export const removeFunnel = createAction('removeFunnel');
export const addFunnel = createAction('addFunnel');

const defaultState = {
  filters: { group: { value: 'sessions' }, timeSection: 'day' },
  events: [],
  funnels: [],
};

export default createReducer(
  {
    // FILTERS
    [addFilter]: (state, payload) => {
      const { name, value, extra } = payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [name]: {
            value,
            extra,
            previous: state.filters[name] || { remove: true },
          },
        },
      };
    },
    [addRawFilters]: (state, payload) => ({
      ...state,
      filters: { ...state.filters, ...payload },
    }),
    [setupFilters]: (state, payload) => ({ ...state, filters: payload }),
    [resetFilters]: state => ({ ...state, filters: {} }),

    [removeFilter]: (state, payload) => {
      const { filters } = state;
      delete filters[payload];
      return { ...state, filters };
    },
    [backFilter]: (state, payload) => {
      const name = payload;
      return {
        ...state,
        filters: { ...state.filters, [name]: state.filters[name].previous },
      };
    },

    // EVENTS
    [setEvents]: (state, payload) => ({ ...state, events: payload }),
    [removeEvent]: (state, payload) => ({
      ...state,
      events: state.events.filter(e => e.id !== payload.id),
    }),
    [addEvent]: (state, payload) => ({
      ...state,
      events: state.events.concat([payload]),
    }),
    [editEvent]: (state, { id, name, description }) => ({
      ...state,
      events: state.events.map(e => (e.id === id ? { ...e, name, description } : e)),
    }),

    // FUNNELS
    [addFunnel]: (state, payload) => ({
      ...state,
      funnels: state.funnels.concat([payload]),
    }),
    [setFunnels]: (state, payload) => ({ ...state, ...payload }),
    [removeFunnel]: (state, payload) => ({
      ...state,
      events: state.funnels.filter(e => e.id === payload.id),
    }),
  },
  defaultState,
);
