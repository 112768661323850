import React from 'react';
import { format } from 'date-fns';
import Icon from 'components/ui/Icon';
import css from './style.module.scss';

const shortCuts = {
  today: 'Today',
  last_7_days: 'Last 7 days',
  last_14_days: 'Last 14 days',
  last_30_days: 'Last 30 days',
  last_month: 'Last month',
  last_3_months: 'Last 3 months',
  last_12_months: 'Last 12 months',
  single_day: 'Single Day',
};
const getLabel = (currentShortcut, from, to) => {
  if (currentShortcut === 'custom') {
    return `${format(from, 'MMMM DD, YYYY')} - ${format(to, 'MMMM DD, YYYY')}`;
  }
  return shortCuts[currentShortcut];
};


const DatesDisplayer = ({
  displayerRef,
  from,
  to,
  onClick,
  currentShortcut,
}) => (
  <div className={css.datesWrapper} ref={displayerRef} onClick={onClick}>
    <div className={css.name}>Range</div>
    <div className={css.datesDisplayer}>
      {currentShortcut && (
        <>
          <span className={css.date}>{getLabel(currentShortcut, from, to)}</span>
          <Icon name="Arrow" className={css.arrow} />
        </>
      )}
      {!currentShortcut && (
        <>
          <span className={css.date}>{format(from, 'YYYY-MM-DD')}</span>
          <span className={css.separator}>
            <Icon name="Arrow" />
          </span>
          <span className={css.date}>{format(to, 'YYYY-MM-DD')}</span>
        </>
      )}
    </div>
  </div>
);

export default DatesDisplayer;
