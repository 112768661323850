import React from 'react';
import Loading from 'components/ui/Loading';
import ScriptViewer from 'components/ScriptViewer';
import Blocker from 'components/ui/Blocker';
import {
  SectionTitle,
} from 'components/ui/Typography';
import Collaborators from './Collaborators';
import Domain from './Domain';
import EditForm from './Form';
import Whitelist from './Whitelist';
import Danger from './Danger';
import css from './style.module.scss';


export default ({ app }) => {
  if (!app) {
    return <Loading />;
  }
  return (
    <Blocker
      can={() => app.userRole === 'owner'}
      redirectTo={`/a/${app.uuid}/`}
    >
      <div className={css.settings}>
        <div className={css.section}>
          <EditForm app={app} />
        </div>
        <div className={css.section}>
          <Collaborators app={app} />
        </div>

        <div className={css.section}>
          <SectionTitle className={css.title}>Tracking script</SectionTitle>
          <ScriptViewer app={app} />
        </div>
        <div className={css.section}>
          <Whitelist app={app} />
        </div>

        <div className={css.section}>
          <Domain app={app} />
        </div>
        <div className={css.section}>
          <Danger app={app} />
        </div>
      </div>
    </Blocker>
  );
};
