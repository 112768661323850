import React, { useState } from 'react';
import { useColors } from 'hooks/useTheme';
import DataLoader from 'components/apps/Details/DataLoader';
import css from './style.module.scss';
import SectionHeader from '../../../SectionHeader';
import BarChart from './BarChart';
import SourceDetails from './SourceDetails';

const sources = {
  referrals: {
    name: 'Referrals',
    description:
      'Referral visits are visits that came to your site from differents sources, like other websites or applications. ',
    color: 'lightBlue10',
  },
  organic: {
    name: 'Organic',
    description:
      'The organic visits are from search engines, like Google, Bing, Yahoo and DuckDuckGo ',
    color: 'green10',
  },
  direct: {
    name: 'Direct, email and IM',
    description:
      "Direct visits are from visitors that already knows your website and typed your urls in the browser's address bar. It may also contain traffic from emails and instant messaging apps.",
    color: 'purple10',
  },
};

export const Referrals = ({
  app, params, addFilter,
}) => {
  const [currentSource, setSource] = useState('referrals');
  const colors = useColors();
  return (
    <div className={css.sourcesContainer}>
      <SectionHeader
        title="Sources"
        down
        download={false}
        subtitle="All the sources that sent visitors to your website. These data are calculated on the unique visits. "
      />
      <DataLoader
        url={`/apps/${app.uuid}/visits/sources`}
        params={{ ...params, group: { value: 'sessions' } }}
      >
        {(data) => {
          const items = Object.keys(data.sources).map(i => ({
            id: i,
            value: data.sources[i],
            ...sources[i],
          }));
          return (
            <div className={css.sources}>
              <div className={css.general}>
                <BarChart
                  items={items}
                  selected={currentSource}
                  onClick={(item) => {
                    setSource(item);
                  }}
                />
              </div>
              <div className={css.details}>
                <SourceDetails
                  params={params}
                  app={app}
                  addFilter={addFilter}
                  current={currentSource}
                  description={sources[currentSource].description}
                  name={sources[currentSource].name}
                  color={colors[sources[currentSource].color]}
                />
              </div>
            </div>
          );
        }}
      </DataLoader>

    </div>
  );
};

export default Referrals;
