import React, { useState, useRef } from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  startOfDay, endOfDay,
} from 'date-fns';

import css from './style.module.scss';

const DatePicker = ({ from, to, handleSelect }) => {
  const [dates, setDates] = useState({ from, to });
  const toRef = useRef();
  const handleFromChange = (from) => {
    // Change the from date and focus the "to" input field
    setDates({ from: startOfDay(from), to: dates.to });
  };

  const handleToChange = (to) => {
    const d = { from: dates.from, to: endOfDay(to), label: 'custom' };

    setDates({ from: dates.from, to });

    handleSelect(d);
  };

  const modifiers = { start: dates.from, end: dates.to };
  return (
    <div className={css.datePickerWrapper}>
      <div className={css.dateWrapper}>
        From
        <DayPickerInput
          value={from}
          placeholder="From"
          format="LL"
          className="Selectable"
        // formatDate={formatDate}
        // parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 1,
            onDayClick: () => toRef.current.getInput().focus(),
          }}
          onDayChange={handleFromChange}
        />

      </div>

      <div className={css.dateWrapper}>
        To
        <DayPickerInput
          ref={toRef}
          value={to}
          placeholder="To"
          className="Selectable"
          format="LL"
          // formatDate={formatDate}
          // parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [dates.from, { from: dates.from, to: dates.to }],
            disabledDays: [
              {
                after: new Date(),
                before: from,
              },
            ],
            modifiers,
            month: from,
            fromMonth: from,
            numberOfMonths: 1,
          }}
          onDayChange={handleToChange}
        />
      </div>

      {/* <DayPickerInput
        className="Selectable"
        numberOfMonths={document.body.clientWidth < 750 ? 1 : 2}
        toMonth={endOfMonth(new Date())}
        selectedDays={[dates.from, { from: dates.from, to: dates.to }]}
        modifiers={modifiers}
        onDayClick={onDayClick}
        disabledDays={[
          {
            after: new Date(),
          },
        ]}
      /> */}
    </div>
  );
};

export default DatePicker;
